/*
@File: Disin Template Style

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Preloader CSS
** - Header Top CSS
** - Navbar CSS
** - Home Slider CSS
** - Counter CSS
** - About CSS
** - Services CSS
** - Expertise CSS
** - Video CSS
** - Doctors CSS
** - Blog CSS
** - Newsletter CSS
** - Footer CSS
** - Copyright CSS
** - Emergency CSS
** - Welcome CSS
** - Speciality CSS
** - Appointment CSS
** - Banner CSS
** - Review Slider CSS
** - Page Title CSS
** - Doctor Search CSS
** - Doctor CSS
** - Doctor Details CSS
** - Blog Details CSS
** - Location CSS
** - Drop CSS
** - Map CSS
** - Faq CSS
** - 404 CSS
** - Coming CSS
** - Testimonial CSS
** - Sign Up CSS
** - Login CSS
** - Privacy Policy CSS
** - Department CSS
** - Service Details CSS
** - Back to Top CSS
** - Home Four CSS
** - Home Five CSS
*/
html {
  font-size: 62.5%;
}

@font-face {
  font-family: Maax Raw;
  src: url(../web-fonts/Maax-Raw-Bold-205TF_5ae92e11d41d.eot?#iefix) format("embedded-opentype"), url(../web-fonts/Maax-Raw-Bold-205TF_b4c36f08.woff2) format("woff2"), url(../web-fonts/Maax-Raw-Bold-205TF_8201c41c.woff) format("woff"), url(../web-fonts/Maax-Raw-Bold-205TF_72377b96.ttf) format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: Maax Raw;
  src: url(../web-fonts/Maax-Raw-Regular-205TF_d51121c1d41d.eot?#iefix) format("embedded-opentype"), url(../web-fonts/Maax-Raw-Regular-205TF_1a786200.woff2) format("woff2"), url(../web-fonts/Maax-Raw-Regular-205TF_45bfadae.woff) format("woff"), url(../web-fonts/Maax-Raw-Regular-205TF_40ab01c3.ttf) format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
:root {
  scroll-behavior: initial;
}

/*-- Default CSS --*/
body {
  font-family: Maax Raw, sans-serif;
  color: #0d1216;
  background-color: #ffffff;
  font-size: 1.6rem;
  font-weight: 400;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #431a80;
}

p {
  line-height: 1.7;
}

a {
  transition: 0.3s all linear;
  text-decoration: none;
  color: #0d1216;
}
a:hover {
  text-decoration: none;
  color: #431a80;
}

img {
  max-width: 100%;
}

.plr-15 {
  padding-left: 15px;
  padding-right: 15px;
}

.d-table {
  width: 100%;
  height: 100%;
}

.d-table-cell {
  vertical-align: middle;
}

.ptb-100 {
  padding-top: 100px;
  padding-bottom: 100px;
}

.pt-100 {
  padding-top: 100px;
}

.pt-70 {
  padding-top: 70px;
}

.pb-70 {
  padding-bottom: 70px;
}

.pb-100 {
  padding-bottom: 100px;
}

.ptb-70 {
  padding-top: 70px;
  padding-bottom: 70px;
}

.ptb-35 {
  padding-top: 35px;
  padding-bottom: 35px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-50 {
  margin-bottom: 50px;
}

.m-0 {
  margin: 0 !important;
}

.pd-r-25 {
  padding-right: 25px;
}

.pd-r-60 {
  padding-right: 60px;
}

.pd-l-25 {
  padding-left: 25px;
}

.pd-l-60 {
  padding-left: 60px;
}

.mt-25 {
  margin-top: 25px;
}

button:focus {
  outline: 0;
}

.btn.focus,
.btn:focus {
  box-shadow: none;
}

.nk__light-bg {
  background-color: #ffffff;
}

.nk__waves {
  position: relative;
  overflow: hidden;
}

.nk__waves.top img {
  max-width: 100%;
  position: relative;
  bottom: -2px;
  z-index: 10;
}

.nk__waves.bottom img {
  max-width: 100%;
  position: relative;
  top: -2px;
  z-index: 10;
}

.nk__text-center {
  text-align: center;
}

.nk__text-left {
  text-align: left;
}

.nk__text-right {
  text-align: right;
}

.bg-purple {
  background-color: #431a80;
}
.bg-purple * {
  color: #ffffff;
}

.bg-dark-grey {
  background-color: #edf0f2;
}

.bg-lightpurple {
  background-color: #ffffff;
}
.bg-lightpurple * {
  color: #0d1216;
}

.nk__float_left {
  float: left;
}

/*-- End Default CSS --*/
/*----- Home Page One -----*/
/*-- Header Top --*/
.header-top {
  padding: 7px 0;
  background-color: #edf0f2;
  z-index: 1;
}

.header-top-item .header-top-left ul {
  margin: 0;
  padding: 0;
}
.header-top-item .header-top-left ul li {
  display: inline-block;
  list-style-type: none;
  margin-right: 35px;
}
.header-top-item .header-top-left ul li i {
  color: #431a80;
  font-size: 2.2rem;
  position: relative;
  top: 2px;
  margin-right: 6px;
  transition: 0.3s all linear;
}
.header-top-item .header-top-left ul li i.icofont-ambulance-cross {
  font-size: 2.6rem;
}
.header-top-item .header-top-left ul li:last-child {
  margin-right: 0;
}
.header-top-item .header-top-left ul li a {
  display: inline-block;
  transition: 0.3s all linear;
  color: #0d1216;
  font-size: 1.4rem;
}
.header-top-item .header-top-left ul li a:hover {
  color: #431a80;
}
.header-top-item .header-top-left ul li a:hover i {
  color: #0d1216;
}
.header-top-item .header-top-right {
  text-align: right;
}
.header-top-item .header-top-right a.nav-link.nk__download_report {
  display: inline-block;
  color: #ffffff;
  background-color: #431a80;
  font-size: 1.4rem;
  padding: 6px 15px 7px;
  border: 2px solid transparent;
  border-radius: 4px;
  transition: 0.3s all linear;
}
.header-top-item .header-top-right a.nav-link.nk__download_report i {
  font-size: 1.8rem;
  margin-right: 10px;
  vertical-align: baseline;
}
.header-top-item .header-top-right a.nav-link.nk__download_report:hover {
  background-color: #ffffff;
  color: #431a80;
  border-color: #431a80;
}
.header-top-item .header-top-right a.nav-link.nk__download_report:hover i {
  color: #431a80;
}
.header-top-item .header-top-right ul {
  margin: 0;
  padding: 0;
}
.header-top-item .header-top-right ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 10px;
}
.header-top-item .header-top-right ul li:last-child {
  margin-right: 0;
}
.header-top-item .header-top-right ul li a {
  display: block;
  color: #ffffff;
  font-size: 4.2rem;
}
.header-top-item .header-top-right ul li a img {
  max-width: 38px;
  border-radius: 5px;
}
.header-top-item .header-top-right ul li a:hover {
  color: #431a80;
}

/*-- End Header Top --*/
/*-- Navbar --*/
.main-nav {
  background-color: #ffffff;
  padding-top: 0;
  padding-bottom: 0;
  position: relative;
  margin-top: -1px;
}
.main-nav nav {
  padding-top: 15px;
  padding-bottom: 15px;
  padding-right: 0;
  padding-left: 0;
  transition: 0.3s all linear;
}
.main-nav nav ul {
  width: 100%;
}
.main-nav nav .navbar-brand {
  max-width: 220px;
}
.main-nav nav .navbar-brand img {
  width: 220px;
}
.main-nav nav .navbar-brand .nk__sticky_logo {
  display: none;
}
.main-nav nav .navbar-nav {
  margin-left: 50px;
}
.main-nav nav .navbar-nav .nav-item a {
  color: #0d1216;
  text-transform: capitalize;
  margin-left: 20px;
  margin-right: 0;
}
.main-nav nav .navbar-nav .nav-item a:hover, .main-nav nav .navbar-nav .nav-item a:focus, .main-nav nav .navbar-nav .nav-item a.active {
  color: #431a80;
}
.main-nav nav .navbar-nav .nav-item:hover a {
  color: #431a80;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  border: 0;
  border-top: 2px solid #3a173c;
  padding: 10px 0;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus, .main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #431a80;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a {
  color: #0d1216;
}
.main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a:hover, .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a:focus, .main-nav nav .navbar-nav .nav-item .dropdown-menu li:hover a.active {
  color: #ffffff;
}

.search-icon {
  font-size: 16px;
  border: 0;
  outline: none;
  transition: 0.3s all linear;
  color: #0d1216;
  background-color: #d8e0e8;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
}
.search-icon:hover {
  background-color: #0d1216;
  color: #ffffff;
}

.search-toggle .search-icon.icon-close {
  display: none;
}

.search-toggle.opened .search-icon.icon-search {
  display: none;
}

.search-toggle.opened .search-icon.icon-close {
  display: block;
}

.nav-srh {
  position: relative;
}
.nav-srh form .form-control {
  height: 40px;
}
.nav-srh form .icon-search {
  position: absolute;
  top: 0;
  right: 0;
  height: 40px;
}

.is-sticky {
  background-color: #431a80;
  transition: 0.3s all linear;
}
.is-sticky .main-nav {
  background-color: #431a80;
}
.is-sticky .main-nav .navbar-brand .nk__ac_logo {
  display: none;
}
.is-sticky .main-nav .navbar-brand .nk__sticky_logo {
  display: inline-block;
}
.is-sticky .main-nav .navbar-toggler .icon-bar {
  background-color: #ffffff;
}
.is-sticky .main-nav nav .navbar-nav .nav-item a {
  color: #ffffff;
}
.is-sticky .main-nav nav .navbar-nav .nav-item a.active {
  color: #ffffff;
}
.is-sticky .main-nav nav .navbar-nav .nav-item a:hover,
.is-sticky .main-nav nav .navbar-nav .nav-item a:focus {
  color: #ffffff;
}

/* Navbar toggler */
.navbar-toggler {
  border: none !important;
  border-radius: 0;
  padding: 0;
  outline: none !important;
  box-shadow: none !important;
  border-color: none !important;
}
.navbar-toggler:focus {
  outline: 0;
}
.navbar-toggler:hover {
  box-shadow: none;
}
.navbar-toggler .icon-bar {
  width: 35px;
  transition: all 0.3s;
  background: #221638;
  height: 4px;
  display: block;
  border-radius: 3px;
}
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
  left: 4px;
  position: relative;
}
.navbar-toggler .middle-bar {
  opacity: 0;
  margin: 5px 0;
}
.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
  left: 4px;
  position: relative;
}
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
  left: 0;
}
.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
}
.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
  left: 0;
}

/*-- End Navbar --*/
/*-- Home Slider --*/
.slider-5 p,
.slider-6 p {
  margin-top: 50px;
}

.slider-2 h1 {
  font-size: 56px !important;
}

.slider-item {
  height: auto;
  margin-bottom: 50px;
  position: relative;
}
.slider-item .slider-shape img {
  position: absolute;
  top: -40px;
  right: 0;
  max-width: 600px;
}
.slider-item .slider-shape-two img {
  position: absolute;
  top: 0;
  right: 0;
  max-width: 460px;
}
.slider-item .slider-shape-three img {
  position: absolute;
  top: -50px;
  right: 0;
  max-width: 640px;
}
.slider-item .slider-shape-three p {
  margin-bottom: 50px;
}
.slider-item .slider-shape-four img {
  position: absolute;
  top: -100px;
  right: 90px;
  max-width: 480px;
}
.slider-item .slider-shape-four p {
  margin-bottom: 50px;
}
.slider-item .slider-shape-five img {
  position: absolute;
  right: 0;
  max-width: 540px;
}
.slider-item .slider-shape-five p {
  margin-bottom: 50px;
}
.slider-item .slider-shape-six img {
  position: absolute;
  top: -45px;
  right: 90px;
  max-width: 300px;
}
.slider-item .slider-shape-six p {
  margin-bottom: 50px;
}
.slider-item .slider-shape-seven img {
  position: absolute;
  top: -45px;
  right: 40px;
  max-width: 620px;
}
.slider-item .slider-shape-seven p {
  margin-bottom: 50px;
}
.slider-item .slider-text {
  position: relative;
  z-index: 5;
}
.slider-item .slider-text h1 {
  font-size: 66px;
  color: #0d1216;
  margin-bottom: 40px;
  max-width: 991px;
  margin-left: 0;
  line-height: 1.1;
}
.slider-item .slider-text h1 span {
  display: block;
  font-size: 36px;
  line-height: 1.1;
  font-weight: 400;
  margin-top: 5px;
}
.slider-item .slider-text ul {
  padding-left: 0;
}
.slider-item .slider-text ul li {
  font-size: 20px;
  list-style-type: none;
  position: relative;
  padding-left: 30px;
}
.slider-item .slider-text ul li i {
  position: absolute;
  left: 0;
  top: 5px;
}
.slider-item .slider-text p {
  color: #0d1216;
  font-size: 24px;
  margin-bottom: 15px;
  max-width: 575px;
  margin-left: 0;
  line-height: 1.1;
}

.common-btn {
  margin-top: 50px;
}
.common-btn a {
  display: inline-block;
  color: #ffffff;
  background-color: #0d1216;
  font-size: 20px;
  padding: 12px 25px;
  border-radius: 50px;
  margin-right: 20px;
  border: 2px solid #3a173c;
}
.common-btn a:hover {
  background-color: #431a80;
  border: 2px solid #431a80;
  color: #ffffff;
}
.common-btn a:last-child {
  margin-right: 0;
}
.common-btn .cmn-btn-right {
  background-color: transparent;
  color: #0d1216;
  border: 2px solid #3a173c;
  padding-left: 25px;
  padding-right: 25px;
}
.common-btn .cmn-btn-right:hover {
  color: #ffffff;
  background-color: #431a80;
}

.home-slider {
  background-color: #ffffff;
  max-height: 460px;
}
.home-slider.owl-theme .owl-nav {
  margin-top: 0;
}
.home-slider.owl-theme .owl-nav .owl-prev,
.home-slider.owl-theme .owl-nav .owl-next {
  position: absolute;
  top: 45%;
  left: 15px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 28px;
  border-radius: 50%;
  color: #431a80;
  background-color: #ffffff;
  transition: 0.3s all linear;
  padding: 0;
  margin: 0;
  border: 3px solid transparent;
}
.home-slider.owl-theme .owl-nav .owl-prev:hover,
.home-slider.owl-theme .owl-nav .owl-next:hover {
  color: #ffffff;
  background-color: #431a80;
  border-color: #ffffff;
}
.home-slider.owl-theme .owl-nav .owl-prev i,
.home-slider.owl-theme .owl-nav .owl-next i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.home-slider.owl-theme .owl-nav .owl-next {
  left: auto;
  right: 15px;
}
.home-slider.owl-theme .owl-dots {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 10px;
}
.home-slider.owl-theme .owl-dots .owl-dot span {
  width: 20px;
  height: 5px;
  margin: 5px 4px;
  background-color: #edf0f2;
  transition: 0.3s all linear;
}
.home-slider.owl-theme .owl-dots .owl-dot.active span, .home-slider.owl-theme .owl-dots .owl-dot:hover span {
  background: #ffffff;
  width: 30px;
  border: 5px solid #ffffff;
}

/*-- End Home Slider --*/
/*-- Counter --*/
.counter-bg {
  padding-top: 5px;
  padding-bottom: 5px;
  position: relative;
  z-index: 1;
}
.counter-bg > div {
  border-right: 1px solid #ffffff;
}

.counter-item {
  margin-bottom: 0;
  text-align: center;
  color: #0d1216;
}
.counter-item:nth-last-of-type(3) {
  border-right: none;
}
.counter-item i {
  display: block;
  color: #431a80;
  font-size: 75px;
  margin-bottom: 20px;
  transition: 0.3s all linear;
}
.counter-item h3 {
  font-size: 4.6rem;
  color: #0d1216;
  margin-bottom: 8px;
}
.counter-item p {
  margin-bottom: 0;
  font-size: 2rem;
}
.counter-item:hover i {
  transform: scale(1.2);
}

/*-- End Counter --*/
/*-- About --*/
.about-item {
  margin-bottom: 30px;
}
.about-item h2 {
  font-size: 38px;
  margin-bottom: 20px;
}
.about-item p {
  margin-bottom: 25px;
}
.about-item ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.about-item ul li {
  list-style-type: none;
  display: block;
  font-size: 18px;
  color: #0d1216;
  margin-bottom: 12px;
}
.about-item ul li:last-child {
  margin-bottom: 0;
}
.about-item ul li i {
  display: inline-block;
  margin-right: 10px;
  color: #431a80;
  font-size: 22px;
  position: relative;
  top: 2px;
  font-weight: 700;
}
.about-item a {
  display: inline-block;
  color: #0d1216;
  background-color: #edf0f2;
  font-size: 22px;
  padding: 12px 45px;
  border: 2px solid transparent;
  border-radius: 4px;
}
.about-item a:hover {
  color: #431a80;
  background-color: #ffffff;
  border-color: #431a80;
}

.about-left {
  position: relative;
}
.about-left img {
  max-width: 100%;
  z-index: 1;
  animation: a-seven 10s infinite linear;
}
.about-left:before {
  display: none;
}
.about-left:after {
  display: none;
}

@keyframes a-one {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-20px, -20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes a-two {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(20px, 20px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.about-right {
  padding-left: 40px;
  position: relative;
}
.about-right img {
  position: absolute;
  top: -130px;
  right: 0;
  z-index: -1;
  width: 460px;
  animation: a-three 5s infinite linear;
  opacity: 0.2;
}

@keyframes a-three {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 35px);
  }
  100% {
    transform: translate(0, 0);
  }
}
/*-- End About --*/
/*-- Services --*/
.section-title {
  margin-bottom: 40px;
  margin-top: -7px;
}
.section-title h2 {
  font-weight: 400;
  font-size: 4.2rem;
  margin-bottom: 0;
  padding-bottom: 15px;
  position: relative;
}

.service-item {
  margin-bottom: 30px;
  box-shadow: 0px 0px 25px 0px rgba(221, 221, 221, 0.7490196078);
  text-align: center;
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  padding-left: 15px;
  padding-right: 15px;
}
.service-item:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  opacity: 0.5;
  background-color: #0d1216;
  opacity: 0;
  z-index: -1;
  transition: 0.3s all linear;
}
.service-item:hover {
  box-shadow: none;
}
.service-item:hover:before {
  opacity: 1;
}
.service-item:hover .service-front {
  display: none;
  transform: scale(0.5);
}
.service-item:hover .service-end {
  display: block;
  transform: scale(1);
}
.service-item:hover .service-end p {
  margin-bottom: 24px;
}
.service-item .service-front {
  transition: 0.3s all linear;
  display: block;
}
.service-item .service-front i {
  display: block;
  color: #4d93e9;
  font-size: 65px;
  margin-bottom: 20px;
}
.service-item .service-front h3 {
  font-size: 20px;
  margin-bottom: 12px;
}
.service-item .service-front p {
  margin-bottom: 0;
}
.service-item .service-end {
  position: relative;
  display: none;
  transform: scale(0.5);
  padding-top: 4px;
  padding-bottom: 5px;
}
.service-item .service-end i {
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
  font-size: 170px;
  color: #fff;
  opacity: 0.1;
  z-index: -1;
}
.service-item .service-end h3 {
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 20px;
}
.service-item .service-end p {
  margin-bottom: 0;
  color: #ffffff;
}
.service-item .service-end a {
  display: inline-block;
  color: #0d1216;
  background-color: #ffffff;
  padding: 12px 15px;
}
.service-item .service-end a:hover {
  color: #ffffff;
  background-color: #0d1216;
}

/*-- End Services --*/
/*-- Expertise --*/
.expertise-item {
  text-align: center;
}
.expertise-item a {
  color: #fff;
}
.expertise-item .expertise-inner {
  background-color: #0d1216;
  padding: 42px 25px 35px 25px;
  border-radius: 10px;
  margin-bottom: 30px;
}
.expertise-item .expertise-inner:hover i {
  box-shadow: none;
}
.expertise-item .expertise-inner i {
  display: block;
  width: 70px;
  height: 70px;
  line-height: 70px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 50%;
  color: #0d1216;
  font-size: 30px;
  margin-bottom: 35px;
  box-shadow: 0px 0px 0px 7px rgba(255, 255, 255, 0.6509803922);
  background-color: #ffffff;
  transition: 0.3s all linear;
  position: relative;
  top: 8px;
}
.expertise-item .expertise-inner h3 {
  color: #ffffff;
  font-size: 22px;
  margin-bottom: 14px;
}
.expertise-item .expertise-inner p {
  margin-bottom: 0;
  color: #ffffff;
}
.expertise-item .expertise-right {
  padding-left: 80px;
  position: relative;
}
.expertise-item .expertise-right:before {
  position: absolute;
  content: "";
  top: -10px;
  left: 70px;
  width: 430px;
  height: 380px;
  z-index: -1;
  border-radius: 10px;
  animation: a-one 5s infinite linear;
  background-color: #0d1216;
}
.expertise-item .expertise-right:after {
  position: absolute;
  content: "";
  bottom: -10px;
  right: -10px;
  width: 430px;
  height: 380px;
  z-index: -1;
  border-radius: 10px;
  animation: a-two 5s infinite linear;
  background-color: #0d1216;
}
.expertise-item .expertise-right img {
  width: 100%;
  z-index: 1;
  animation: a-seven 10s infinite linear;
}

@keyframes a-seven {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
/*-- End Expertise --*/
/*-- Video --*/
.video-item {
  padding: 35px;
}
.video-item .video-content {
  text-align: left;
  padding-left: 30px;
  position: relative;
  margin-left: 0;
}
.video-item .video-content:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 5px;
  height: 100%;
  background-color: #ffffff;
}
.video-item .video-content h3 {
  color: #ffffff;
  font-size: 3.6rem;
  margin-bottom: 25px;
}
.video-item .video-content p {
  color: #ffffff;
  margin-bottom: 0;
}

.video-wrap .react-tabs__tab-list {
  position: relative;
  margin: 0;
  border: none;
}
.video-wrap .react-tabs__tab-list .react-tabs__tab {
  display: inline-block;
  color: #0d1216;
  font-size: 16px;
  background-color: transparent;
  padding: 25px 49.4px 22px 49.3px;
  border-radius: 0;
  border: none;
  bottom: 0;
}
.video-wrap .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  background-color: #0d1216;
  color: #fff;
}

/*-- End Video --*/
/*-- Doctors --*/
.doctor-item {
  margin-bottom: 30px;
  background-color: #ffffff;
  text-align: center;
  box-shadow: 0px 0px 20px 0px #ddd;
  border-radius: 10px;
}
.doctor-item:hover .doctor-top:before {
  opacity: 0.6;
  transform: scale(1);
}
.doctor-item:hover .doctor-top a {
  opacity: 1;
  bottom: 20px;
}
.doctor-item .doctor-top {
  position: relative;
  border-radius: 10px;
}
.doctor-item .doctor-top a {
  display: inline-block;
  color: #0d1216;
  padding: 15px 0;
  position: absolute;
  border-radius: 5px;
  left: 0;
  right: 0;
  bottom: 10px;
  opacity: 0;
  max-width: 160px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
}
.doctor-item .doctor-top a:hover {
  color: #ffffff;
  background-color: #0d1216;
}
.doctor-item .doctor-top img {
  width: 100%;
  border-radius: 10px;
}
.doctor-item .doctor-bottom {
  padding-top: 25px;
  padding-bottom: 25px;
}
.doctor-item .doctor-bottom h3 a {
  display: block;
  font-size: 22px;
  margin-bottom: 10px;
  color: #232323;
}
.doctor-item .doctor-bottom h3 a:hover {
  color: #0d1216;
}
.doctor-item .doctor-bottom span {
  display: block;
  font-size: 1.6rem;
}

.doctor-btn {
  text-align: center;
}
.doctor-btn a {
  margin-top: 30px;
  display: inline-block;
  color: #232323;
  font-size: 18px;
  border: 2px solid #386ae6;
  padding: 18px 55px;
  border-radius: 10px;
}
.doctor-btn a:hover {
  color: #ffffff;
  background-color: #386ae6;
}

/*-- End Doctors --*/
/*-- Blog --*/
.blog-area {
  background-color: #f8fbff;
}

.blog-item {
  margin-bottom: 30px;
  border-radius: 10px;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.4196078431);
}
.blog-item:hover .blog-top {
  overflow: hidden;
}
.blog-item:hover .blog-top img {
  transform: scale(1.1);
}
.blog-item .blog-top {
  overflow: hidden;
}
.blog-item .blog-top img {
  width: 100%;
  border-radius: 10px;
  transition: 0.3s all linear;
}
.blog-item .blog-top a {
  display: block;
}
.blog-item .blog-bottom {
  padding-top: 25px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
}
.blog-item .blog-bottom h3 {
  margin-bottom: 0;
}
.blog-item .blog-bottom h3 a {
  font-size: 20px;
  color: #232323;
  margin-bottom: 12px;
  transition: 0.3s all linear;
  line-height: 1.4;
  display: block;
}
.blog-item .blog-bottom h3 a:hover {
  color: #0d1216;
}
.blog-item .blog-bottom p {
  color: #232323;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.blog-item .blog-bottom ul {
  margin: 0;
  padding: 0;
  padding-top: 20px;
  border-top: 1px solid #b1b8ed;
}
.blog-item .blog-bottom ul li {
  display: inline-block;
  list-style-type: none;
  color: #0046c0;
}
.blog-item .blog-bottom ul li i {
  color: #0046c0;
}
.blog-item .blog-bottom ul li:last-child {
  float: right;
}
.blog-item .blog-bottom ul li:last-child i {
  margin-right: 6px;
}
.blog-item .blog-bottom ul li a {
  display: block;
  color: #0046c0;
  font-size: 1.6rem;
}
.blog-item .blog-bottom ul li a:hover {
  color: #0d1216;
}
.blog-item .blog-bottom ul li a:hover i {
  color: #0d1216;
}
.blog-item .blog-bottom ul li a i {
  display: inline-block;
  font-size: 22px;
  position: relative;
  top: 4px;
  transition: 0.3s all ease;
}

/*-- End Blog --*/
/*-- Newsletter --*/
.newsletter-wrap {
  padding: 45px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6705882353);
  background-color: #ffffff;
  border-radius: 10px;
}

.newsletter-item h2 {
  font-size: 36px;
  margin-bottom: 15px;
}
.newsletter-item p {
  margin-bottom: 0;
  color: #676767;
  max-width: 550px;
  margin-left: 0;
}
.newsletter-item .newsletter-form {
  position: relative;
}
.newsletter-item .newsletter-form .form-control {
  height: 75px;
  border-radius: 10px;
  border: 1px solid #3a173c;
  padding-left: 25px;
  font-size: 18px;
}
.newsletter-item .newsletter-form .form-control:focus {
  box-shadow: none;
  border: 1px solid #3a173c;
}
.newsletter-item .newsletter-form ::-moz-placeholder {
  color: #333333;
}
.newsletter-item .newsletter-form ::placeholder {
  color: #333333;
}
.newsletter-item .newsletter-form .newsletter-btn {
  border-radius: 10px;
  padding: 16px 30px;
  font-size: 18px;
  position: absolute;
  top: 7px;
  right: 7px;
  color: #ffffff;
  background-color: #0d1216;
  transition: 0.3s all linear;
  opacity: 1;
}
.newsletter-item .newsletter-form .newsletter-btn:hover {
  background-color: #0d1216;
}
.newsletter-item .newsletter-form .validation-danger {
  color: #dc3545;
  margin-top: 10px;
}

/*-- End Newsletter --*/
/*-- Footer --*/
footer {
  background-color: #d5d5d5;
  position: relative;
}

.footer-item {
  margin-bottom: 30px;
}
.footer-item h3 {
  color: #0d1216;
  font-size: 2rem;
}
.footer-item .footer-contact h3 {
  margin-bottom: 30px;
}
.footer-item .footer-contact ul {
  margin: 0;
  padding: 0;
}
.footer-item .footer-contact ul li {
  list-style-type: none;
  display: block;
  font-size: 1.6rem;
  padding-left: 40px;
  position: relative;
  margin-bottom: 30px;
}
.footer-item .footer-contact ul li:last-child {
  margin-bottom: 0;
  margin-left: 0;
}
.footer-item .footer-contact ul li:last-child i {
  top: 2px;
}
.footer-item .footer-contact ul li a {
  display: block;
  margin-bottom: 7px;
}
.footer-item .footer-contact ul li a:last-child {
  margin-bottom: 0;
}
.footer-item .footer-contact ul li a:hover {
  margin-left: 5px;
}
.footer-item .footer-contact ul li a:hover i {
  left: 5px;
}
.footer-item .footer-contact ul li i {
  position: absolute;
  top: 2px;
  left: 0;
  font-size: 28px;
  transition: 0.3s all linear;
}
.footer-item .footer-quick h3 {
  margin-bottom: 30px;
}
.footer-item .footer-quick ul {
  margin: 0;
  padding: 0;
}
.footer-item .footer-quick ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 20px;
}
.footer-item .footer-quick ul li:last-child {
  margin-bottom: 0;
}
.footer-item .footer-quick ul li a {
  display: block;
  font-size: 1.6rem;
}
.footer-item .footer-quick ul li a:hover {
  margin-left: 5px;
}
.footer-item .nk__quick-links ul li {
  position: relative;
  padding-left: 40px;
  margin-bottom: 30px;
}
.footer-item .nk__quick-links ul li:last-child {
  margin-bottom: 0;
}
.footer-item .nk__quick-links ul li i {
  position: absolute;
  left: 0;
  top: 2px;
  font-size: 28px;
  transition: 0.3s all linear;
}
.footer-item .footer-feedback h3 {
  margin-bottom: 30px;
  color: #0d1216;
}
.footer-item .footer-feedback .form-group {
  margin-bottom: 20px;
}
.footer-item .footer-feedback .form-group .form-control {
  font-size: 1.6rem;
  background-color: transparent;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7803921569);
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 15px;
  color: #ffffff;
}
.footer-item .footer-feedback .form-group .form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7803921569);
}
.footer-item .footer-feedback .form-group ::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7803921569);
}
.footer-item .footer-feedback .form-group ::placeholder {
  color: rgba(255, 255, 255, 0.7803921569);
}
.footer-item .footer-feedback .feedback-btn {
  background-color: #ffffff;
  padding: 12px 35px;
  border-radius: 30px;
  color: #0d1216;
  transition: 0.3s all linear;
}
.footer-item .footer-feedback .feedback-btn:hover {
  color: #ffffff;
  background-color: #0d1216;
}
.footer-item .footer-feedback .nk__certificate {
  text-align: left;
}
.footer-item .footer-feedback .nk__certificate span {
  display: block;
  margin-top: 15px;
  font-size: 18px;
}
.footer-item .footer-feedback .nk__certificate img {
  max-width: 145px;
  display: inline;
}
.footer-item .footer-feedback .nk__certificate.two__logo a > span {
  float: left;
}
.footer-item .footer-feedback .nk__certificate.two__logo a > span span {
  display: block;
}
.footer-item .footer-feedback .nk__certificate.two__logo img {
  margin-right: 15px;
  max-width: 145px;
}

/*-- End Footer --*/
/*-- Copyright --*/
.copyright-area {
  background-color: #0d1216;
  color: #ffffff;
}
.copyright-area .copyright-item {
  padding-top: 20px;
  padding-bottom: 20px;
}
.copyright-area .copyright-item p {
  margin-bottom: 0;
}
.copyright-area .copyright-item p a {
  display: inline-block;
  color: #ffffff;
}
.copyright-area .copyright-item p a:hover {
  color: #f37435;
}

/*-- End Copyright --*/
/*----- End Home Page One -----*/
/*----- Home Page Two -----*/
/*-- Home Slider --*/
.home-slider-two.owl-theme .owl-dots .owl-dot.active span,
.home-slider-two.owl-theme .owl-dots .owl-dot:hover span {
  background: #9dbdfc;
}

.home-slider-two.owl-theme .owl-nav .owl-prev,
.home-slider-two.owl-theme .owl-nav .owl-next {
  background-color: #bdcce2;
}
.home-slider-two .slider-item {
  height: 830px;
}
.home-slider-two .slider-item .slider-shape img {
  position: absolute;
  top: -160px;
  left: 0;
  width: 525px;
  animation: a-three 5s infinite linear;
  z-index: -1;
}
.home-slider-two .slider-item::before {
  background-color: transparent;
}
.home-slider-two .slider-item .slider-text h1 {
  color: #232323;
}
.home-slider-two .slider-item .slider-text p {
  color: #000000;
}
.home-slider-two .slider-item .slider-text .common-btn a {
  border: 2px solid #3a173c;
  color: #ffffff;
  background-color: #0d1216;
  position: relative;
}
.home-slider-two .slider-item .slider-text .common-btn a:hover {
  color: #0d1216;
  background-color: transparent;
}
.home-slider-two .slider-item .slider-text .common-btn .cmn-btn-right {
  background-color: transparent;
  color: #0d1216;
}
.home-slider-two .slider-item .slider-text .common-btn .cmn-btn-right:hover {
  color: #ffffff;
  background-color: #0d1216;
}

/*-- End Home Slider --*/
/*-- Emergency --*/
.emergency-area {
  margin-top: -125px;
  position: relative;
  z-index: 1;
}

.emergency-bg {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  padding: 45px 0 15px 110px;
  border-radius: 10px;
}

.emergency-item {
  margin-bottom: 30px;
  position: relative;
}
.emergency-item i {
  display: inline-block;
  position: absolute;
  top: 5px;
  left: 0;
  text-align: center;
  width: 45px;
  height: 45px;
  line-height: 45px;
  border-radius: 50%;
  color: #0d1216;
  font-size: 20px;
  box-shadow: 0px 0px 0px 4px rgba(255, 255, 255, 0.6117647059);
  background-color: #ffffff;
}
.emergency-item .emergency-inner {
  padding-left: 75px;
}
.emergency-item .emergency-inner h3 {
  color: #ffffff;
  margin-bottom: 18px;
  font-size: 22px;
}
.emergency-item .emergency-inner p {
  color: #ffffff;
  margin-bottom: 0;
}

/*-- End Emergency --*/
/*-- Welcome --*/
.welcome-left {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 0 10px 10px 0;
  margin-bottom: 30px;
  width: 100%;
  height: 100%;
}
.welcome-left img {
  display: none;
}

.welcome-item ul {
  margin: 0;
  padding: 0;
  max-width: 430px;
}
.welcome-item ul li {
  list-style-type: none;
  display: block;
  position: relative;
  margin-bottom: 40px;
}
.welcome-item ul li:hover i {
  box-shadow: 0px 0px 0px 10px #3a173c;
}
.welcome-item ul li:last-child {
  margin-bottom: 0;
}
.welcome-item ul li i {
  display: inline-block;
  position: absolute;
  top: 9px;
  left: 0;
  text-align: center;
  font-size: 35px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  border-radius: 50%;
  color: #0d1216;
  box-shadow: 0px 0px 0px 10px #3a173c;
  background-color: #f1f1f1;
  transition: 0.3s all linear;
}
.welcome-item ul li .welcome-inner {
  padding-left: 110px;
}
.welcome-item ul li .welcome-inner h3 {
  font-size: 22px;
  color: #232323;
  margin-bottom: 10px;
}
.welcome-item ul li .welcome-inner p {
  margin-bottom: 0;
}

.welcome-right {
  padding-left: 70px;
  padding-top: 0;
}

.section-title-two {
  max-width: 430px;
  margin-left: 0;
  margin-bottom: 40px;
  margin-top: -5px;
}
.section-title-two span {
  display: inline-block;
  color: #0d1216;
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.section-title-two h2 {
  font-size: 38px;
  color: #232323;
}

/*-- End Welcome --*/
/*-- Speciality --*/
.speciality-area .section-title-two {
  padding-top: 50px;
  padding-left: 15px;
}

.speciality-item .speciality-inner {
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.6509803922);
  padding: 35px;
  margin-bottom: 30px;
}
.speciality-item .speciality-inner:hover i {
  color: #ffffff;
  background-color: #6096fd;
}
.speciality-item .speciality-inner i {
  display: block;
  color: #6096fd;
  background-color: #e9eef5;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  border-radius: 50%;
  font-size: 28px;
  margin-bottom: 18px;
  transition: 0.3s all linear;
}
.speciality-item .speciality-inner h3 {
  font-size: 22px;
  margin-bottom: 12px;
}
.speciality-item .speciality-inner p {
  margin-bottom: 0;
}

.speciality-right {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 10px 0 0 10px;
  position: relative;
  width: 100%;
  height: 100%;
}
.speciality-right img {
  display: none;
}
.speciality-right .speciality-emergency {
  position: absolute;
  bottom: 20px;
  left: -70px;
  background-color: #0d1216;
  padding: 30px 295px 25px 40px;
  border-radius: 10px;
}
.speciality-right .speciality-emergency .speciality-icon {
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  transform: rotate(45deg);
  display: inline-block;
  position: relative;
}
.speciality-right .speciality-emergency .speciality-icon i {
  display: inline-block;
  line-height: 45px;
  color: #0d1216;
  text-align: center;
  font-size: 20px;
  transform: rotate(-45deg);
  position: absolute;
  top: 0px;
  left: 12px;
}
.speciality-right .speciality-emergency h3 {
  color: #ffffff;
  font-size: 24px;
  margin-bottom: 8px;
  position: absolute;
  top: 23px;
  right: 70px;
}
.speciality-right .speciality-emergency p {
  margin-bottom: 0;
  color: #ffffff;
  position: absolute;
  right: 142px;
  top: 56px;
}

.speciality-left {
  padding-left: 55px;
  padding-right: 120px;
}

/*-- End Speciality --*/
/*-- Video --*/
.video-wrap-two .video-nav .video-nav-item a {
  padding-left: 49.7px;
  padding-right: 49px;
}
.video-wrap-two .video-item a {
  color: #0d1216;
}
.video-wrap-two .nav-pills .nav-link,
.video-wrap-two .nav-pills .show > .nav-link {
  border-top: 0;
}
.video-wrap-two .nav-pills .nav-link.active,
.video-wrap-two .nav-pills .show > .nav-link {
  border-top: 0;
  background-color: #0d1216;
  color: #ffffff;
}

/*-- End Video --*/
/*-- Counter --*/
.counter-area {
  background-color: #ffffff;
}

.counter-area-two .counter-bg {
  margin-top: 0;
  bottom: 0;
}

/*-- End Counter --*/
/*-- Appointment --*/
.appointment-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.appointment-item {
  max-width: 770px;
  margin-left: auto;
  margin-right: auto;
  background-color: #ffffff;
  padding: 80px 100px 80px;
  border-radius: 10px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.appointment-item h2 {
  color: #0d1216;
  font-size: 30px;
  margin-bottom: 15px;
}
.appointment-item span {
  display: block;
  font-size: 1.6rem;
  margin-bottom: 50px;
}
.appointment-item .appointment-form .form-group {
  padding-left: 70px;
  position: relative;
  margin-bottom: 60px;
}
.appointment-item .appointment-form .form-group i {
  display: inline-block;
  color: #0d1216;
  position: absolute;
  top: 11px;
  left: 0;
  font-size: 50px;
}
.appointment-item .appointment-form .form-group label {
  margin-bottom: 10px;
  font-size: 1.6rem;
}
.appointment-item .appointment-form .form-group .form-control {
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid rgba(0, 70, 192, 0.6196078431);
  font-size: 1.6rem;
  padding-left: 0;
  padding-bottom: 8px;
  height: 40px;
  background-color: transparent;
}
.appointment-item .appointment-form .form-group .form-control:focus {
  box-shadow: none;
  border-bottom: 1px solid rgba(0, 70, 192, 0.6196078431);
}
.appointment-item .appointment-form .appointment-btn {
  color: #ffffff;
  background-color: #0d1216;
  font-size: 1.6rem;
  border-radius: 5px;
  padding: 18px 45px;
  transition: 0.3s all linear;
}
.appointment-item .appointment-form .appointment-btn:hover {
  color: #ffffff;
  background-color: #0d1216;
}

/*-- End Appointment --*/
/*----- End Home Page Two -----*/
/*----- Home Page Three -----*/
/*-- Banner --*/
.banner-area {
  height: 700px;
  position: relative;
}
.banner-area .heart-shape img {
  position: absolute;
  bottom: 35px;
  left: 0;
  z-index: -1;
}

.banner-item {
  position: relative;
}
.banner-item h1 {
  font-size: 48px;
  margin-bottom: 25px;
  max-width: 575px;
  margin-left: 0;
}
.banner-item p {
  color: #616263;
  margin-bottom: 35px;
  max-width: 575px;
  margin-left: 0;
}
.banner-item .common-btn-two a {
  display: inline-block;
  color: #ffffff;
  background-color: #0d1216;
  font-size: 1.6rem;
  padding: 12px 18px;
  border-radius: 5px;
  margin-right: 20px;
  border: 2px solid transparent;
}
.banner-item .common-btn-two a:hover {
  background-color: transparent;
  border: 2px solid #3a173c;
  color: #0d1216;
}
.banner-item .common-btn-two a:last-child {
  margin-right: 0;
}
.banner-item .common-btn-two .cmn-btn-right-two {
  background-color: transparent;
  color: #0d1216;
  border: 2px solid #3a173c;
  padding-left: 25px;
  padding-right: 25px;
}
.banner-item .common-btn-two .cmn-btn-right-two:hover {
  color: #ffffff;
  background-color: #0d1216;
}
.banner-item .banner-right img {
  position: absolute;
}
.banner-item .banner-right img:nth-child(1) {
  top: -125px;
  right: 10px;
  max-width: 510px;
  z-index: 1;
  margin-left: auto;
  margin-right: auto;
}
.banner-item .banner-right img:nth-child(2) {
  top: -125px;
  right: 20px;
  max-width: 510px;
  animation: a-four 5s infinite linear;
  margin-left: auto;
  margin-right: auto;
}
.banner-item .banner-right img:nth-child(3) {
  top: -125px;
  right: 0px;
  max-width: 510px;
  animation: a-five 5s infinite linear;
  margin-left: auto;
  margin-right: auto;
}
.banner-item .banner-right img:nth-child(4) {
  bottom: 215px;
  left: 50px;
  max-width: 360px;
  z-index: -1;
  animation: a-eight 20s infinite linear;
}

@keyframes a-eight {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(75px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes a-four {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(-10px, -10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
@keyframes a-five {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(10px, 10px);
  }
  100% {
    transform: translate(0, 0);
  }
}
/*-- End Banner --*/
/*-- About --*/
.hospital-area {
  position: relative;
}
.hospital-area .hospital-shape img {
  position: absolute;
  bottom: 80px;
  right: 0;
  width: 290px;
  animation: a-six 5s infinite linear;
}

@keyframes a-six {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(0, 40px);
  }
  100% {
    transform: translate(0, 0);
  }
}
.hospital-item {
  margin-bottom: 30px;
  position: relative;
}
.hospital-item .hospital-play-btn {
  display: inline-block;
  width: 110px;
  height: 110px;
  line-height: 110px;
  border-radius: 50%;
  color: #0d1216;
  text-align: center;
  font-size: 32px;
  background-color: #ffffff;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 2;
  top: 40%;
  cursor: pointer;
}
.hospital-item .hospital-play-btn:hover {
  color: #ffffff;
  background-color: #0d1216;
}
.hospital-item h2 {
  font-size: 38px;
  margin-bottom: 15px;
}
.hospital-item p {
  margin-bottom: 40px;
}
.hospital-item ul {
  margin: 0;
  padding: 0;
  margin-bottom: 50px;
}
.hospital-item ul li {
  list-style-type: none;
  display: block;
  font-size: 18px;
  color: #0d1216;
  margin-bottom: 20px;
}
.hospital-item ul li:last-child {
  margin-bottom: 0;
}
.hospital-item ul li i {
  color: #6096fd;
  font-size: 22px;
  margin-right: 8px;
  position: relative;
  bottom: -1px;
}
.hospital-item .hospital-btn {
  display: inline-block;
  color: #ffffff;
  background-color: #0d1216;
  border-radius: 10px;
  font-size: 1.6rem;
  padding: 20px 42px;
}
.hospital-item .hospital-btn:hover {
  color: #ffffff;
  background-color: #0d1216;
}
.hospital-item .hospital-left-one {
  margin-top: 100px;
  position: relative;
  z-index: 1;
}
.hospital-item .hospital-left-one:before {
  position: absolute;
  content: "";
  right: -17px;
  bottom: -15px;
  width: 430px;
  height: 380px;
  z-index: -1;
  border-radius: 10px;
  background-color: #0d1216;
}
.hospital-item .hospital-left-one img {
  width: 100%;
  z-index: 1;
}
.hospital-item .hospital-left-two {
  margin-left: 15px;
  margin-bottom: 100px;
  position: relative;
  z-index: 1;
}
.hospital-item .hospital-left-two:before {
  position: absolute;
  content: "";
  left: -17px;
  top: -15px;
  width: 295px;
  height: 380px;
  z-index: -1;
  border-radius: 10px;
  background-color: #0d1216;
}
.hospital-item .hospital-left-two img {
  width: 100%;
  z-index: 1;
}

.hospital-right {
  max-width: 525px;
  margin-left: 85px;
}

/*-- End About --*/
/*-- Speciality --*/
.speciality-right-two {
  position: relative;
}
.speciality-right-two:before {
  position: absolute;
  content: "";
  top: -25px;
  right: 0;
  width: 405px;
  height: 300px;
  z-index: -1;
  background-color: #0d1216;
}

/*-- End Speciality --*/
/*-- Welcome --*/
.welcome-left-two {
  position: relative;
  z-index: 1;
}

/*-- End Welcome --*/
/*-- Counter --*/
.counter-area-three .counter-bg {
  margin-top: 0;
  bottom: 0;
}

/*-- End Counter --*/
/*-- Review Slider --*/
.review-area {
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.review-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  opacity: 0.8;
  top: 0;
  left: 0;
  background-color: #0d1216;
}

.main {
  max-width: 770px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 80px;
  padding-left: 80px;
  padding-right: 80px;
  position: relative;
}
.main:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 330px;
  top: 65px;
  left: 0;
  right: 0;
  background-color: #ffffff;
}

.slick-dotted.slick-slider {
  margin-bottom: 25px;
}

.slider-nav {
  margin-bottom: 30px;
  position: relative;
  max-width: 440px;
  margin-left: auto;
  margin-right: auto;
}
.slider-nav .slick-dots {
  bottom: -145px;
}
.slider-nav .slick-dots li {
  margin: 0;
}
.slider-nav .slick-dots li button:before {
  font-size: 12px;
  opacity: 1;
}
.slider-nav .slick-dots li.slick-active button:before {
  opacity: 1;
  color: #0d1216;
}
.slider-nav .slick-track {
  padding-top: 2px;
}
.slider-nav div {
  text-align: center;
}
.slider-nav div .review-img {
  position: relative;
  margin-top: 10px;
  margin-right: 15px;
  margin-left: 15px;
}
.slider-nav div .review-img img {
  display: inline-block;
  margin-bottom: 45px;
  width: 90px;
  height: 90px;
  border: 3px solid #3a173c;
  border-radius: 50%;
  transition: 0.3s all linear;
  margin-top: 2px;
  cursor: pointer;
}
.slider-nav div h3 {
  font-size: 24px;
  margin-bottom: 12px;
}
.slider-nav div span {
  display: block;
  color: #3776d0;
}

.slick-slide .review-details {
  opacity: 0;
}

.slick-slide:focus {
  outline: 0;
}

.slick-slide.slick-center .review-details {
  opacity: 1;
}
.slick-slide.slick-center .review-img img {
  transform: scale(1.3);
}

.slider-for div p {
  margin-bottom: 0;
  color: #000000;
  font-size: 1.6rem;
  text-align: center;
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}

.feedback-slider.owl-theme .owl-nav .owl-prev,
.feedback-slider.owl-theme .owl-nav .owl-next {
  background-color: #fff;
  color: #0d1216;
}
.feedback-slider.owl-theme .owl-nav .owl-prev:hover,
.feedback-slider.owl-theme .owl-nav .owl-next:hover {
  background-color: #0d1216;
}

.feedback-item {
  max-width: 770px;
  width: 100%;
  background-color: #fff;
  margin: auto;
  text-align: center;
  padding: 60px 30px;
  border-radius: 10px;
}
.feedback-item .client-img img {
  width: 90px !important;
  height: 90px;
  margin: auto;
}
.feedback-item .client-img h3 {
  margin-top: 20px;
  font-size: 22px;
}
.feedback-item p {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}
.feedback-item p:last-child {
  margin-bottom: 0;
}

/*-- End Review Slider --*/
/*----- End Home Page Three -----*/
/*----- Doctor Page -----*/
/*-- Page Title --*/
.page-title-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 400px;
  text-align: center;
  position: relative;
}
.page-title-area:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: #0d1216;
}

.page-title-item {
  position: relative;
}
.page-title-item h2 {
  color: #f8f8f8;
  font-size: 48px;
  margin-bottom: 20px;
}
.page-title-item ul {
  margin: 0;
  padding: 0;
}
.page-title-item ul li {
  list-style-type: none;
  display: inline-block;
  color: #f8f8f8;
  font-size: 18px;
  margin-right: 25px;
  position: relative;
}
.page-title-item ul li:last-child {
  margin-right: 0;
}
.page-title-item ul li a {
  display: inline-block;
  color: #f8f8f8;
}
.page-title-item ul li a:hover {
  color: #0d1216;
}
.page-title-item ul li::before {
  content: ">";
  position: absolute;
  right: -17px;
  top: 1px;
}
.page-title-item ul li:last-child::before {
  display: none;
}

/*-- End Page Title --*/
/*-- Doctor Search --*/
.doctor-search-wrap {
  padding-top: 45px;
  padding-bottom: 15px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 0px #ddd;
  margin-top: -80px;
  z-index: 1;
  position: relative;
  background-color: #ffffff;
  max-width: 970px;
  margin-right: auto;
  margin-left: auto;
}

.doctor-search-item {
  margin-bottom: 30px;
  position: relative;
  max-width: 340px;
  margin-right: auto;
  margin-left: auto;
}
.doctor-search-item .form-group {
  position: relative;
  padding-left: 60px;
  margin-bottom: 0;
}
.doctor-search-item .form-group i {
  display: inline-block;
  color: #0d1216;
  font-size: 45px;
  position: absolute;
  top: 8px;
  left: 0;
}
.doctor-search-item .form-group label {
  font-size: 1.6rem;
  margin-bottom: 5px;
}
.doctor-search-item .form-group .form-control {
  font-size: 1.6rem;
  border-radius: 0;
  border: 0;
  padding-left: 0;
  padding-top: 0;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccd9f2;
}
.doctor-search-item .form-group .form-control:focus {
  border-bottom: 1px solid #3a173c;
  box-shadow: none;
}
.doctor-search-item .doctor-search-btn {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 18px;
}

/*-- End Doctor Search --*/
/*-- Doctor --*/
.doctors-area-two {
  position: relative;
}
.doctors-area-two .doctor-shape {
  position: absolute;
  top: 45%;
  left: 0;
  animation: a-six 5s infinite linear;
}

/*-- End Doctor --*/
/*----- End Doctor Page -----*/
/*----- Apoointment Page -----*/
/*-- Page Title --*/
/*-- End Page Title --*/
/*-- Appointment --*/
.appointment-item-two {
  margin-bottom: 30px;
  box-shadow: 0px 0px 20px 0px #ddd;
  padding: 80px 65px 80px;
  position: relative;
}
.appointment-item-two .appointment-shape {
  position: absolute;
  bottom: -65px;
  left: -74px;
  width: 310px;
  animation: a-six 5s infinite linear;
}

.appointment-item-two-right {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
}
.appointment-item-two-right .appointment-item-content {
  background-color: #f6f6f6;
  padding: 95px 40px 100px;
  margin: 0 40px;
  border-radius: 10px;
}
.appointment-item-two-right .appointment-item-content h2 {
  color: #0d1216;
  font-size: 24px;
  margin-bottom: 30px;
}
.appointment-item-two-right .appointment-item-content ul {
  margin: 0;
  padding: 0;
}
.appointment-item-two-right .appointment-item-content ul li {
  list-style-type: none;
  position: relative;
  border-bottom: 1px solid #eee;
  padding: 10px 0;
}
.appointment-item-two-right .appointment-item-content ul li span {
  float: right;
}

/*-- End Appointment --*/
/*----- End Apoointment Page -----*/
/*----- Doctor Details Page -----*/
/*-- Page Title --*/
.page-title-item-two {
  position: relative;
  text-align: left;
  max-width: 500px;
  margin-left: auto;
}
.page-title-item-two h2 {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 14px;
}
.page-title-item-two h3 {
  font-size: 32px;
  color: #fefefe;
  margin-bottom: 15px;
}
.page-title-item-two p {
  margin-bottom: 0;
  font-size: 20px;
  color: #fefefe;
  padding-bottom: 55px;
}

/*-- End Page Title --*/
/*-- Doctor Details --*/
.doctor-details-area {
  background-color: #431a80;
}

.doctor-details-item {
  margin-bottom: 30px;
}
.doctor-details-item img {
  width: 100%;
  border-radius: 10px;
  margin-bottom: 50px;
}
.doctor-details-item .doctor-details-contact {
  padding: 0 40px;
  margin-bottom: 50px;
}
.doctor-details-item .doctor-details-contact h3 {
  font-size: 2.6rem;
  color: #ffffff;
  margin-bottom: 30px;
}
.doctor-details-item .doctor-details-contact ul {
  margin: 0;
  padding: 0;
}
.doctor-details-item .doctor-details-contact ul li {
  list-style-type: none;
  display: block;
  color: #ffffff;
  margin-bottom: 25px;
}
.doctor-details-item .doctor-details-contact ul li:last-child {
  margin-bottom: 0;
}
.doctor-details-item .doctor-details-contact ul li i {
  display: inline-block;
  color: #ffffff;
  margin-right: 10px;
  font-size: 18px;
  position: relative;
  top: 2px;
}
.doctor-details-item .doctor-details-contact ul li a {
  color: #ffffff;
}
.doctor-details-item .doctor-details-work {
  padding: 0 40px;
  padding-bottom: 50px;
}
.doctor-details-item .doctor-details-work h3 {
  font-size: 2.6rem;
  color: #ffffff;
  margin-bottom: 30px;
}
.doctor-details-item .doctor-details-work .appointment-item-two-right {
  padding-top: 0;
  padding-bottom: 0;
}
.doctor-details-item .doctor-details-work .appointment-item-two-right .appointment-item-content {
  padding: 0;
  background-color: transparent;
  margin: 0;
}
.doctor-details-item .doctor-details-work .appointment-item-two-right .appointment-item-content ul li {
  color: #ffffff;
  padding: 5px 5px;
}
.doctor-details-item .doctor-details-work .appointment-item-two-right .appointment-item-content ul li:first-child {
  border-top: 1px solid #eee;
}
.doctor-details-item .doctor-details-biography {
  padding-left: 30px;
  margin-bottom: 40px;
}
.doctor-details-item .doctor-details-biography .nk__title {
  font-size: 1.6rem;
}
.doctor-details-item .doctor-details-biography:last-child {
  margin-bottom: 0;
}
.doctor-details-item .doctor-details-biography h3 {
  font-size: 3.8rem;
  color: #ffffff;
  margin-bottom: 5px;
}
.doctor-details-item .doctor-details-biography p {
  color: #ffffff;
}
.doctor-details-item .doctor-details-biography p:last-child {
  margin-bottom: 0;
}
.doctor-details-item .doctor-details-biography ul {
  margin: 0;
  padding: 0;
}
.doctor-details-item .doctor-details-biography ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 15px;
}
.doctor-details-item .doctor-details-biography ul li:last-child {
  margin-bottom: 0;
}

/*-- End Doctor Details --*/
/*-- Appointment --*/
.appointment-area-three .appointment-item {
  max-width: 565px;
  margin-left: auto;
  margin-right: 80px;
  margin-top: 50px;
}
.appointment-area-three .appointment-item .appointment-shape {
  bottom: 0;
  top: -95px;
  left: -74px;
  width: 250px;
}
/*-- End Appointment --*/
/*----- End Doctor Details Page -----*/
/*----- Blog Details Page -----*/
/*-- Page Title --*/
/*-- End Page Title --*/
/*-- Blog Details --*/
.blog-details-item {
  margin-bottom: 30px;
}
.blog-details-item .blog-details-img img {
  width: 100%;
  margin-bottom: 30px;
}
.blog-details-item .blog-details-img h2 {
  color: #232323;
  font-size: 28px;
  margin-bottom: 16px;
  line-height: 1.4;
}
.blog-details-item .blog-details-img ul {
  margin: 0;
  padding: 0;
  margin-bottom: 20px;
}
.blog-details-item .blog-details-img ul li {
  list-style-type: none;
  display: inline-block;
  color: #3a5ed3;
  font-size: 16px;
  margin-right: 50px;
}
.blog-details-item .blog-details-img ul li a {
  font-weight: 400;
  color: #0d1216;
  display: inline-block;
}
.blog-details-item .blog-details-img ul li a:hover {
  color: #0d1216;
}
.blog-details-item .blog-details-img ul li:last-child {
  margin-right: 0;
}
.blog-details-item .blog-details-img ul li i {
  display: inline-block;
  font-size: 20px;
  margin-right: 5px;
}
.blog-details-item .blog-details-img p {
  margin-bottom: 22px;
}
.blog-details-item .blog-details-previous h3 {
  color: #232323;
  margin-top: 10px;
  font-size: 1.6rem;
  margin-bottom: 25px;
}
.blog-details-item .blog-details-previous ul {
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.blog-details-item .blog-details-previous ul li {
  list-style-type: none;
  display: block;
  margin-bottom: 6px;
}
.blog-details-item .blog-details-previous ul li:last-child {
  margin-bottom: 0;
}
.blog-details-item .blog-details-previous .prev-next ul {
  margin: 0;
  padding: 0;
}
.blog-details-item .blog-details-previous .prev-next ul li {
  list-style-type: none;
  display: inline-block;
}
.blog-details-item .blog-details-previous .prev-next ul li:last-child {
  float: right;
}
.blog-details-item .blog-details-previous .prev-next ul li a {
  display: block;
  font-size: 1.6rem;
  color: #0045be;
  border: 1px solid #0045be;
  padding: 10px 25px;
  border-radius: 6px;
}
.blog-details-item .blog-details-previous .prev-next ul li a:hover {
  color: #ffffff;
}
.blog-details-item .blog-details-search {
  position: relative;
  margin-bottom: 40px;
}
.blog-details-item .blog-details-search .form-control {
  height: 50px;
  border-radius: 6px;
  border: 1px solid #3a173c;
  padding-left: 25px;
}
.blog-details-item .blog-details-search .form-control:focus {
  box-shadow: none;
}
.blog-details-item .blog-details-search .blog-details-btn {
  color: #ffffff;
  background-color: #0d1216;
  border-radius: 6px;
  position: absolute;
  top: 0;
  right: 0;
  font-size: 17px;
  padding: 11px 20px;
  transition: 0.3s all linear;
}
.blog-details-item .blog-details-recent {
  margin-bottom: 40px;
}
.blog-details-item .blog-details-recent h3 {
  font-size: 22px;
  margin-bottom: 30px;
}
.blog-details-item .blog-details-recent ul {
  margin: 0;
  padding: 0;
}
.blog-details-item .blog-details-recent ul li {
  list-style-type: none;
  display: block;
  position: relative;
  padding-left: 120px;
  padding-bottom: 25px;
}
.blog-details-item .blog-details-recent ul li:last-child {
  padding-bottom: 0;
}
.blog-details-item .blog-details-recent ul li img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100px;
  height: 95px;
}
.blog-details-item .blog-details-recent ul li a {
  display: block;
  font-size: 1.6rem;
  color: #232323;
  margin-bottom: 15px;
  padding-top: 4px;
}
.blog-details-item .blog-details-recent ul li a:hover {
  color: #0d1216;
}
.blog-details-item .blog-details-recent ul li ul li {
  display: inline-block;
  padding-left: 0;
  color: #3a5ed3;
  font-size: 1.6rem;
  margin-right: 25px;
  padding-bottom: 0;
}
.blog-details-item .blog-details-recent ul li ul li a {
  font-weight: 400;
  color: #0d1216;
  display: inline-block;
}
.blog-details-item .blog-details-recent ul li ul li a:hover {
  color: #0d1216;
}
.blog-details-item .blog-details-recent ul li ul li i {
  font-size: 20px;
  margin-right: 2px;
  display: inline-block;
}
.blog-details-item .blog-details-recent ul li ul li:last-child {
  margin-right: 0;
}
.blog-details-item .blog-details-category {
  margin-bottom: 40px;
}
.blog-details-item .blog-details-category h3 {
  font-size: 22px;
  margin-bottom: 30px;
}
.blog-details-item .blog-details-category ul {
  margin: 0;
  padding: 0;
}
.blog-details-item .blog-details-category ul li {
  list-style-type: none;
  display: block;
  border-bottom: 1px solid #dee8f5;
  position: relative;
  padding-left: 20px;
  padding-bottom: 15px;
  margin-bottom: 15px;
}
.blog-details-item .blog-details-category ul li:hover a {
  color: #ffffff;
}
.blog-details-item .blog-details-category ul li:hover:before {
  width: 100%;
}
.blog-details-item .blog-details-category ul li:before {
  position: absolute;
  content: "";
  left: 0;
  top: -3px;
  width: 3px;
  height: 30px;
  z-index: -1;
  transition: 0.3s all linear;
  background-color: #2362bc;
}
.blog-details-item .blog-details-category ul li:last-child {
  margin-bottom: 0;
}
.blog-details-item .blog-details-category ul li a {
  display: block;
  z-index: 1;
}
.blog-details-item .blog-details-tags h3 {
  font-size: 22px;
  margin-bottom: 30px;
}
.blog-details-item .blog-details-tags ul {
  margin: 0;
  padding: 0;
}
.blog-details-item .blog-details-tags ul li {
  list-style-type: none;
  display: inline-block;
  margin-bottom: 8px;
  margin-right: 4px;
}
.blog-details-item .blog-details-tags ul li a {
  display: block;
  font-size: 1.6rem;
  background-color: #e5e5e5;
  padding: 12px 25px;
  border-radius: 8px;
}
.blog-details-item .blog-details-tags ul li a:hover {
  color: #ffffff;
}

.blog-details-form {
  position: relative;
}
.blog-details-form .blog-details-shape {
  position: absolute;
  top: 130px;
  right: 0;
  width: 360px;
  animation: a-six 5s infinite linear;
}
.blog-details-form .blog-details-form-wrap {
  max-width: 770px;
}
.blog-details-form .blog-details-form-wrap h2 {
  font-size: 25px;
  margin-bottom: 30px;
}
.blog-details-form .blog-details-form-wrap .form-group {
  margin-bottom: 30px;
}
.blog-details-form .blog-details-form-wrap .form-group textarea {
  height: auto !important;
  padding: 20px;
  z-index: 1;
  position: relative;
}
.blog-details-form .blog-details-form-wrap .form-group .form-control {
  height: 50px;
  padding-left: 20px;
  border-radius: 6px;
  border: 0;
  background-color: #fafafa;
  box-shadow: none;
}
.blog-details-form .blog-details-form-wrap .blog-details-form-btn {
  color: #ffffff;
  font-size: 1.6rem;
  padding: 15px 34px;
  background-color: #0d1216;
  transition: 0.3s all linear;
}
.blog-details-form .blog-details-form-wrap .blog-details-form-btn:hover {
  color: #ffffff;
}

/*-- End Blog Details --*/
/*-- Blog --*/
.blog-area-two .section-title {
  margin-bottom: 0;
}
.blog-area-two .section-title h2 {
  font-size: 22px;
  margin-bottom: 30px;
  padding-bottom: 0;
}
.blog-area-two .section-title h2:before {
  display: none;
}

/*-- End Blog --*/
/*----- End Blog Details Page -----*/
/*----- Contact Page -----*/
/*-- Page Title --*/
/*-- End Page Title --*/
/*-- Location --*/
.location-wrap .location-item {
  margin-bottom: 30px;
  text-align: center;
  border-left: 0px solid #431a80;
  padding: 30px 15px;
}
.location-wrap .location-item i {
  display: inline-block;
  width: 95px;
  height: 95px;
  line-height: 95px;
  border-radius: 50%;
  color: #ffffff;
  text-align: center;
  font-size: 32px;
  margin-bottom: 25px;
  background-color: #0d1216;
}
.location-wrap .location-item h3 {
  font-size: 25px;
  margin-bottom: 15px;
}
.location-wrap .location-item p {
  margin-bottom: 0;
}
.location-wrap .location-item a {
  display: inline-block;
  color: #0d1216;
}

/*-- End Location --*/
/*-- Drop --*/
.drop-area .speciality-right-three {
  border-radius: 0;
}

.drop-item .drop-left {
  max-width: 1020px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 70px;
  padding-bottom: 70px;
}
.drop-item .drop-left .form-control {
  font-size: 1.6rem;
}
.drop-item .drop-left h2 {
  font-size: 32px;
  margin-bottom: 35px;
  padding-right: 50px;
  line-height: 1.4;
  color: #0d1216;
}
.drop-item .drop-left .form-group {
  margin-bottom: 30px;
}
.drop-item .drop-left .form-group .form-control {
  height: 50px;
  background-color: #f2f3f5;
  padding-left: 20px;
  border: none;
  box-shadow: none;
}
.drop-item .drop-left .form-group textarea {
  padding-top: 20px;
  height: auto !important;
}
.drop-item .drop-left .drop-btn {
  font-size: 1.6rem;
  transition: 0.3s all linear;
  box-shadow: none;
  font-size: 22px;
  padding: 12px 45px;
  border-radius: 4px;
  border: 2px solid transparent;
  color: #431a80;
  background-color: #ffffff;
  border-color: #431a80;
}
.drop-item .drop-left .drop-btn:hover {
  color: #ffffff;
  background-color: #431a80;
}
.drop-item .drop-left .list-unstyled {
  color: #dc3545;
  font-size: 13px;
  margin-top: 10px;
}
.drop-item .drop-left .text-danger {
  color: #dc3545;
  margin-top: 25px;
  margin-bottom: 0;
  font-size: 24px;
}
.drop-item .drop-left .text-success {
  color: #28a745;
  margin-top: 25px;
  font-size: 24px;
}

/*-- End Drop --*/
/*-- Map --*/
#map {
  height: 600px;
}

/*-- End Map --*/
/*----- End Contact Page -----*/
/*----- FAQ PAGE -----*/
/*-- Faq --*/
.faq-head h2 {
  margin-bottom: 35px;
  font-size: 25px;
}

.faq-wrap {
  margin-bottom: 50px;
}
.faq-wrap:last-child {
  margin-bottom: 30px;
}

/*-- End Faq --*/
/*-- Footer --*/
.footer-two {
  padding-top: 100px;
}

/*-- End Footer --*/
/*----- END FAQ PAGE -----*/
/*----- ERROR PAGE -----*/
/*-- 404 --*/
.error-item {
  height: 100vh;
  text-align: center;
  padding: 50px 20px;
}
.error-item h1 {
  font-size: 100px;
  margin-bottom: 0;
}
.error-item p {
  margin-bottom: 10px;
  font-size: 35px;
  color: #000000;
}
.error-item span {
  display: block;
}
.error-item a {
  display: inline-block;
  color: #ffffff;
  background-color: #0d1216;
  border-radius: 10px;
  padding: 15px 30px;
  margin-top: 30px;
  font-size: 17px;
}
.error-item a:hover {
  background-color: #0d1216;
}

/*-- End 404 --*/
/*----- END ERROR PAGE -----*/
/*----- COMING SOON PAGE -----*/
/*-- Coming --*/
.coming-item {
  height: 100vh;
  text-align: center;
}
.coming-item h1 {
  font-size: 75px;
  font-style: italic;
  margin-bottom: 20px;
}
.coming-item p {
  margin-bottom: 40px;
  max-width: 865px;
  margin-left: auto;
  margin-right: auto;
}
.coming-item .coming-wrap {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.coming-item .coming-wrap .coming-inner {
  text-align: center;
  background-color: #efefef;
  padding-top: 15px;
  padding-bottom: 12px;
  margin-bottom: 30px;
}
.coming-item .coming-wrap .coming-inner #days,
.coming-item .coming-wrap .coming-inner #hours,
.coming-item .coming-wrap .coming-inner #minutes,
.coming-item .coming-wrap .coming-inner #seconds {
  font-size: 40px;
  color: #232323;
  margin-bottom: 5px;
}
.coming-item .coming-wrap .coming-inner span {
  font-size: 16px;
  font-style: italic;
  display: block;
  font-weight: normal;
}
.coming-item ul {
  margin: 0;
  padding: 0;
}
.coming-item ul li {
  list-style-type: none;
  display: inline-block;
  margin-right: 2px;
  margin-left: 2px;
}
.coming-item ul li a {
  display: block;
  color: #ffffff;
  background-color: #232323;
  width: 35px;
  height: 35px;
  line-height: 35px;
  border-radius: 50%;
}
.coming-item ul li a:hover {
  background-color: #0d1216;
}

/*-- End Coming --*/
/*----- END COMING SOON PAGE -----*/
/*----- TESTIMONIAL PAGE -----*/
/*-- Testimonial --*/
.testimonial-area {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  text-align: center;
  position: relative;
}
.testimonial-area:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #0d1216;
  opacity: 0.5;
}
.testimonial-area .owl-theme .owl-nav {
  margin-top: 25px;
}
.testimonial-area .testimonial-wrap {
  background-color: #ffffff;
  border-radius: 10px;
  position: relative;
  max-width: 830px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 60px;
}
.testimonial-area .testimonial-wrap h2 {
  font-size: 38px;
  margin-bottom: 50px;
}
.testimonial-area .testimonial-wrap .testimonial-slider .owl-prev {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50% !important;
  color: #ffffff !important;
  background-color: #232323 !important;
  font-size: 25px !important;
  transition: 0.3s all linear;
}
.testimonial-area .testimonial-wrap .testimonial-slider .owl-prev:hover {
  background-color: #3a173c !important;
}
.testimonial-area .testimonial-wrap .testimonial-slider .owl-next {
  width: 40px;
  height: 40px;
  line-height: 40px !important;
  border-radius: 50% !important;
  color: #ffffff !important;
  background-color: #232323 !important;
  font-size: 25px !important;
  transition: 0.3s all linear;
}
.testimonial-area .testimonial-wrap .testimonial-slider .owl-next:hover {
  background-color: #3a173c !important;
}
.testimonial-area .testimonial-wrap .testimonial-slider .testimonial-item img {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 30px;
}
.testimonial-area .testimonial-wrap .testimonial-slider .testimonial-item h3 {
  font-size: 26px;
  margin-bottom: 15px;
}
.testimonial-area .testimonial-wrap .testimonial-slider .testimonial-item p {
  margin-bottom: 0;
  max-width: 620px;
  margin-left: auto;
  margin-right: auto;
}

/*-- End Testimonial --*/
/*----- END TESTIMONIAL PAGE -----*/
/*----- SIGN UP PAGE -----*/
/*-- Sign Up --*/
.signup-left {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.signup-left img {
  display: none;
}

.signup-item {
  max-width: 590px;
  margin-left: auto;
  margin-right: auto;
}
.signup-item .signup-head {
  margin-bottom: 40px;
}
.signup-item .signup-head h2 {
  font-size: 38px;
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}
.signup-item .signup-head h2:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 80px;
  height: 5px;
  background-color: #0d1216;
  border-radius: 5px;
}
.signup-item .signup-head p {
  font-size: 16px;
  margin-bottom: 0;
}
.signup-item .signup-head p a {
  display: inline-block;
  color: #0d1216;
}
.signup-item .signup-head p a:hover {
  color: #0d1216;
}
.signup-item .signup-form .form-group {
  margin-bottom: 30px;
}
.signup-item .signup-form .form-group .form-control {
  height: 45px;
  padding-left: 20px;
  font-size: 1.6rem;
}
.signup-item .signup-form .form-group .form-control:focus {
  border: 1px solid #3a173c;
  box-shadow: none;
}
.signup-item .signup-form .form-group .form-check .form-check-input {
  width: 15px !important;
  height: 15px !important;
  top: 3px;
  margin-top: 0;
}
.signup-item .signup-form .form-group .form-check label a {
  display: inline-block;
  color: #0d1216;
}
.signup-item .signup-form .form-group .form-check label a:hover {
  color: #0d1216;
}
.signup-item .signup-form .form-group .forgot-pass a {
  display: inline-block;
  border-bottom: 2px solid #3a173c;
}
.signup-item .signup-form .form-group .forgot-pass a:hover {
  color: #0d1216;
}
.signup-item .signup-form .signup-btn {
  color: #ffffff;
  background-color: #0d1216;
  width: 100%;
  display: block;
  border-radius: 5px;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 5px;
  transition: 0.3s all linear;
}
.signup-item .signup-form .signup-btn:hover {
  background-color: #232323;
}

/*-- End Sign Up --*/
/*----- END SIGN UP PAGE -----*/
/*----- LOGIN PAGE -----*/
/*-- Login --*/
.login-left {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.login-left img {
  display: none;
}

/*-- End Login --*/
/*----- END LOGIN PAGE -----*/
/*----- PRIVACY POLICY PAGE -----*/
/*-- Privacy --*/
.privacy-area h2 {
  font-size: 26px;
  margin-bottom: 15px;
}
.privacy-area p {
  margin-bottom: 15px;
}
.privacy-area p:last-child {
  margin-bottom: 0;
}
.privacy-area ul li {
  margin-bottom: 15px;
}
.privacy-area ul li:last-child {
  margin-bottom: 0;
}

/*-- End Privacy --*/
/*----- END PRIVACY POLICY PAGE -----*/
/*----- DEPARTMENTS PAGE -----*/
/*-- Department --*/
.department-item {
  text-align: center;
  border: 1px solid #3a173c;
  padding-top: 30px;
  padding-bottom: 30px;
  transition: 0.3s all linear;
  margin-bottom: 30px;
  position: relative;
  z-index: 1;
}
.department-item:before {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  left: 0;
  top: 0;
  z-index: -1;
  background-color: #0d1216;
  transition: 0.3s all linear;
}
.department-item:after {
  position: absolute;
  content: "";
  width: 0;
  height: 100%;
  right: 0;
  top: 0;
  z-index: -1;
  background-color: #0d1216;
  transition: 0.3s all linear;
}
.department-item:hover:before {
  width: 51%;
}
.department-item:hover:after {
  width: 50%;
}
.department-item:hover i {
  color: #ffffff;
}
.department-item:hover h3 {
  color: #ffffff;
}
.department-item:hover p {
  color: #ffffff;
}
.department-item i {
  font-size: 45px;
  display: block;
  margin-bottom: 20px;
  color: #0d1216;
  transition: 0.3s all linear;
}
.department-item h3 {
  margin-bottom: 12px;
  font-size: 26px;
  transition: 0.3s all linear;
}
.department-item p {
  margin-bottom: 0;
  padding-left: 15px;
  padding-right: 15px;
  transition: 0.3s all linear;
}

/*-- End Department --*/
/*----- END DEPARTMENTS PAGE -----*/
/*----- SERVICES DETAILS PAGE -----*/
/*-- Service Details --*/
.services-details-img {
  margin-bottom: 50px;
}
.services-details-img img {
  width: 100%;
  margin-bottom: 30px;
}
.services-details-img h2 {
  font-size: 28px;
  margin-bottom: 16px;
}
.services-details-img P {
  margin-bottom: 20px;
}
.services-details-img blockquote {
  font-size: 1.6rem;
  background-color: rgba(0, 70, 192, 0.0784313725);
  padding: 30px 75px;
  line-height: 26px;
  position: relative;
  margin-bottom: 20px;
}
.services-details-img blockquote i {
  position: absolute;
  display: inline-block;
  top: 20px;
  left: 38px;
  font-size: 32px;
}

.service-details-inner-left {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
}
.service-details-inner-left img {
  display: none;
}

.service-details-inner {
  max-width: 580px;
  margin-left: auto;
  margin-right: auto;
}
.service-details-inner h2 {
  font-size: 30px;
  margin-bottom: 15px;
}
.service-details-inner p {
  margin-bottom: 0;
}

/*-- End Service Details --*/
/*----- END SERVICES DETAILS PAGE -----*/
/*----- ABOUT PAGE -----*/
/*-- Counter --*/
.counter-area-four {
  margin-top: 0;
  margin-bottom: 100px;
}

/*-- End Counter --*/
/*----- END ABOUT PAGE -----*/
/*-- Back To Top --*/
#toTop {
  position: fixed;
  bottom: 30px;
  right: 0;
  cursor: pointer;
  display: none;
  z-index: 10;
}

.go-top {
  position: fixed;
  right: 0;
  bottom: 15px;
  transform: scale(0);
  transition: 0.5s;
  cursor: pointer;
  z-index: 99;
  text-align: center;
  background-color: #ffffff;
  padding: 7px 15px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.go-top.active {
  transform: scale(1);
}
.go-top i {
  color: #06d755;
  display: inline-block;
  text-align: center;
  font-size: 26px;
  border-radius: 50%;
  transition: 0.3s all linear;
  margin-right: 0;
}

.go-top.go-top-whatsapp {
  bottom: 30px;
}
.go-top.go-top-whatsapp:hover i {
  background-color: transparent;
  color: #06d755;
}
.go-top.go-top-whatsapp:hover span {
  color: #06d755;
}
.go-top.go-top-whatsapp span {
  display: block;
  width: 100%;
  margin: 0;
  color: #431a80;
  transition: 0.5s all ease;
}

.go-top.go-top-downloadReport {
  bottom: 90px;
}
.go-top.go-top-downloadReport i {
  color: #431a80;
}

/*-- End Back To Top --*/
/*----- Home Four CSS -----*/
.slider-item-two {
  overflow: hidden;
}
.slider-item-two:before {
  background-color: #000111;
}
.slider-item-two .slider-shape img {
  top: -75px;
  right: 0;
  animation: item-two-ani 35s infinite linear;
}
.slider-item-two .slider-text h1 {
  margin-bottom: 25px;
  line-height: 60px;
}

@keyframes item-two-ani {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.about-area-two .about-item p {
  margin-bottom: 10px;
}
.about-area-two .about-item a {
  margin-top: 20px;
}

.service-area-two .section-title {
  max-width: 415px;
}

.symptoms-img {
  margin-bottom: 30px;
}
.symptoms-img img {
  border-radius: 10px;
  width: 100%;
}

.symptoms-content .section-title {
  margin-bottom: 30px;
}
.symptoms-content .section-title-two {
  max-width: 100%;
}
.symptoms-content p {
  margin-bottom: 20px;
}
.symptoms-content ul {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: -10px;
}
.symptoms-content ul li {
  list-style-type: none;
  display: inline-block;
  flex: 0 0 50%;
  max-width: 50%;
  padding-right: 10px;
  padding-left: 10px;
  margin-bottom: 30px;
  position: relative;
}
.symptoms-content ul li span {
  display: block;
  position: relative;
  box-shadow: 0px 0px 20px 0px rgba(221, 221, 221, 0.5019607843);
  color: #0d1216;
  font-size: 16px;
  padding: 12px 10px 14px 40px;
  border-radius: 5px;
  z-index: 1;
  transition: 0.3s all linear;
}
.symptoms-content ul li span:hover {
  color: #ffffff;
}
.symptoms-content ul li span:hover i {
  color: #ffffff;
}
.symptoms-content ul li span:hover:before {
  height: 100%;
  border-radius: 5px;
}
.symptoms-content ul li span:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #0d1216;
  border-radius: 0 0 5px 5px;
  z-index: -1;
  transition: 0.3s all linear;
}
.symptoms-content ul li span i {
  display: inline-block;
  color: #0d1216;
  font-size: 30px;
  position: absolute;
  top: 9px;
  left: 10px;
  transition: 0.3s all linear;
}

.faq-area-two {
  background-color: #4f87fb;
  position: relative;
}
.faq-area-two .faq-shape img {
  position: absolute;
}
.faq-area-two .faq-shape img:nth-child(1) {
  top: 0;
  right: 0;
}
.faq-area-two .faq-shape img:nth-child(2) {
  left: 0;
  bottom: 0;
}
.faq-area-two .section-title {
  text-align: center;
}
.faq-area-two .section-title h2 {
  color: #ffffff;
  display: inline-block;
}
.faq-area-two .section-title h2:before {
  background-color: #fff;
}
.faq-area-two .faq-img {
  margin-bottom: 30px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;
}
.faq-area-two .faq-img img {
  width: 100%;
  display: none;
}
.faq-area-two .faq-img .popup-youtube {
  display: inline-block;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 50%;
  color: #fff;
  background-color: #4f87fb;
  position: absolute;
  top: 50%;
  left: 20%;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  font-size: 30px;
  cursor: pointer;
  z-index: 1;
  text-align: center;
  transform: translateY(-50%) translateX(-50%);
}
.faq-area-two .faq-img .popup-youtube:hover {
  background-color: #232323;
}

.accordion {
  border: none;
}
.accordion .accordion__item {
  box-shadow: 0 0 1.25rem rgba(108, 118, 134, 0.1);
  background: transparent;
  margin-bottom: 10px;
  position: relative;
  border: none;
}
.accordion .accordion__item:last-child {
  margin-bottom: 0;
}
.accordion .accordion__button {
  border-bottom: none;
  color: #fff;
  cursor: pointer;
  padding: 15px 35px 15px 18px;
  margin: 0;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 8px 8px 0 0;
}
.accordion .accordion__button:hover, .accordion .accordion__button[aria-expanded=true] {
  background-color: #0d1216;
  color: #fff;
  border: 1px solid #3a173c;
}
.accordion .accordion__button:focus {
  outline: 0;
}
.accordion .accordion__button span::before {
  position: absolute;
  height: 100%;
  width: 40px;
  content: "";
  right: 0;
  top: 0;
  z-index: 0;
}
.accordion .accordion__button:before {
  margin-right: 0;
  border-bottom: 3px solid #431a80;
  border-right: none;
  transform: rotate(0deg);
  height: 13px;
  width: 13px;
  position: absolute;
  right: 15px;
  top: 25%;
  z-index: 1;
}
.accordion .accordion__button::after {
  content: "";
  margin-right: 0;
  border-right: 3px solid #431a80;
  transform: rotate(0deg);
  margin-top: 5px;
  height: 13px;
  width: 13px;
  position: absolute;
  right: 20px;
  top: 25%;
  z-index: 1;
}
.accordion .accordion__button[aria-expanded=true]::after, .accordion .accordion__button[aria-selected=true]::after {
  display: none;
}
.accordion .accordion__button[aria-expanded=true]::before, .accordion .accordion__button[aria-selected=true]::before {
  transform: rotate(0deg);
  border-color: #ffffff;
}
.accordion .accordion__panel {
  padding: 15px;
}
.accordion .accordion__panel p {
  color: #fff;
}
.accordion .accordion__panel p:last-child {
  margin-bottom: 0;
}

/*----- End Home Four CSS -----*/
/*----- Home Five CSS -----*/
.home-slider-three .owl-dots {
  display: none !important;
}

.slider-item-three {
  overflow: hidden;
}
.slider-item-three:before {
  display: none;
}
.slider-item-three .slider-text {
  margin-top: -30px;
}
.slider-item-three .slider-text span {
  display: inline-block;
  color: #0d1216;
  position: relative;
  padding-left: 25px;
  margin-bottom: 20px;
}
.slider-item-three .slider-text span:before {
  position: absolute;
  content: "";
  width: 15px;
  height: 3px;
  left: 0;
  bottom: 10px;
  background-color: #0d1216;
  border-radius: 10px;
}
.slider-item-three .slider-text h1 {
  font-size: 60px;
  margin-bottom: 25px;
  color: #0d1216;
}
.slider-item-three .slider-text p {
  margin-bottom: 30px;
  font-size: 22px;
  color: #0d1216;
}
.slider-item-three .slider-text .common-btn a {
  color: #fff;
  background-color: #0046c0;
}
.slider-item-three .slider-text .common-btn a:hover {
  border: 2px solid #3a173c;
  background-color: #0d1216;
}
.slider-item-three .slider-text .common-btn .cmn-btn-right {
  background-color: transparent;
  color: #0046c0;
  border: 2px solid #0046c0;
}
.slider-item-three .slider-text .common-btn .cmn-btn-right:hover {
  background-color: #0046c0;
  color: #fff;
  border: 2px solid #0046c0;
}
.slider-item-three .slider-shape img {
  top: -65px;
  right: 0;
  animation: item-two-ani 40s infinite linear;
}

.spread-area .spread-img {
  margin-bottom: 30px;
}
.spread-area .spread-img img {
  border-radius: 10px;
  width: 100%;
}
.spread-area .spread-content .section-title-two {
  margin-bottom: 25px;
  max-width: 100%;
}
.spread-area .spread-content p {
  margin-bottom: 25px;
}
.spread-area .spread-content ul {
  margin: 0;
  padding: 0;
}
.spread-area .spread-content ul li {
  list-style-type: none;
  display: block;
  position: relative;
  margin-bottom: 30px;
  padding-left: 105px;
}
.spread-area .spread-content ul li:hover img {
  animation: item-two-ani 5s infinite linear;
}
.spread-area .spread-content ul li img {
  position: absolute;
  top: 0;
  left: 0;
  animation: none;
  transition: 0.3s all linear;
}
.spread-area .spread-content ul li h3 {
  font-size: 20px;
}
.spread-area .spread-content ul li p {
  margin-bottom: 0;
}

/*----- End Home Five CSS -----*/
.map-area iframe {
  border: none;
  width: 100%;
}

.faq-content {
  margin-bottom: 50px;
}
.faq-content:last-child {
  margin-bottom: 0;
}
.faq-content h2 {
  margin-bottom: 25px;
  font-size: 25px;
}
.faq-content .accordion .accordion__button {
  border: 1px solid #232323;
  color: #232323;
}
.faq-content .accordion .accordion__button:hover, .faq-content .accordion .accordion__button[aria-expanded=true] {
  background-color: #0d1216;
  color: #fff;
}
.faq-content .accordion .accordion__button:hover:before, .faq-content .accordion .accordion__button[aria-expanded=true]:before {
  border-bottom: 3px solid #fff;
}
.faq-content .accordion .accordion__button:hover:after, .faq-content .accordion .accordion__button[aria-expanded=true]:after {
  border-right: 3px solid #fff;
}
.faq-content .accordion .accordion__button:before {
  border-bottom: 3px solid #232323;
}
.faq-content .accordion .accordion__button:after {
  border-right: 3px solid #232323;
}
.faq-content .accordion .accordion__item {
  margin-bottom: 25px;
}

.nk_facilities_slider_container {
  background-color: #431a80;
  padding-bottom: 90px;
}
.nk_facilities_slider_container .section-title h2 {
  color: #ffffff;
  margin-bottom: 90px;
}
.nk_facilities_slider_container .section-title h2:before {
  background-color: #ffffff;
}
.nk_facilities_slider_container .nk_slide {
  background-color: #ffffff;
  border-radius: 125px;
  transition: 0.3s all linear;
}
.nk_facilities_slider_container .nk_slide:hover {
  background-color: #ffffff;
}
.nk_facilities_slider_container .nk_slide .nk_slide_item_front {
  text-align: center;
  padding: 15px 40px;
}
.nk_facilities_slider_container .nk_slide .nk_slide_item_front img {
  display: inline-block;
  margin-bottom: 0;
  transition: 0.3s all linear;
}
.nk_facilities_slider_container .nk_slide .nk_slide_item_front h3 {
  font-size: 2.2rem;
  transition: 0.3s all linear;
}
.nk_facilities_slider_container .nk_slide .nk_slide_item_front:hover img {
  transform: scale(1);
}
.nk_facilities_slider_container .owl-dots {
  display: none;
}
.nk_facilities_slider_container .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -85px;
  right: 0;
}
.nk_facilities_slider_container .owl-theme .owl-nav [class*=owl-] {
  display: inline-block;
  cursor: pointer;
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 28px;
  border-radius: 50%;
  color: #0d1216;
  background-color: #ffffff;
  transition: 0.3s all linear;
  padding: 0;
  border: 2px solid transparent;
  position: relative;
}
.nk_facilities_slider_container .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #431a80;
  border-color: #ffffff;
}
.nk_facilities_slider_container .owl-theme .owl-nav [class*=owl-] i {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nk__testimonail_slider img {
  max-width: 150px;
  margin-bottom: 25px;
}
.nk__testimonail_slider .nk_slide_item_top p {
  font-size: 1.8rem;
  margin-bottom: 25px;
}
.nk__testimonail_slider .nk_slide_item_bottom i {
  color: #431a80;
}
.nk__testimonail_slider .nk_slide_item_bottom p {
  margin-bottom: 0;
}
.nk__testimonail_slider .nk_slide_item_bottom p:nth-of-type(1) {
  font-weight: 700;
  font-size: 1.8rem;
}
.nk__testimonail_slider .nk_slide_item_bottom p:nth-of-type(2) {
  margin-bottom: 2px;
  font-size: 1.4rem;
}
.nk__testimonail_slider .owl-dots {
  display: none;
}
.nk__testimonail_slider .owl-theme .owl-nav {
  margin-top: 0;
  position: absolute;
  top: -85px;
  right: 0;
}
.nk__testimonail_slider .owl-theme .owl-nav [class*=owl-] {
  display: inline-block;
  cursor: pointer;
  width: 55px;
  height: 55px;
  line-height: 55px;
  font-size: 28px;
  border-radius: 50%;
  color: #431a80;
  background-color: #edf0f2;
  transition: 0.3s all linear;
  padding: 0;
}
.nk__testimonail_slider .owl-theme .owl-nav [class*=owl-]:hover {
  background-color: #431a80;
  color: #ffffff;
}

.health-package-area {
  background-color: #edf0f2;
}
.health-package-area .packages-info-img {
  border-radius: 20px;
}
.health-package-area .section-title h2 {
  color: #431a80;
}
.health-package-area .section-title h2::before {
  background-color: #ffffff;
}
.health-package-area .hp-item {
  background-color: #ffffff;
  height: 100%;
  padding: 25px 25px;
  border-radius: 4px;
  overflow: hidden;
  border: 2px solid transparent;
  border-left: 0px solid #431a80;
}
.health-package-area .hp-item .hp-content h3 {
  font-size: 1.8rem;
  padding-left: 50px;
  position: relative;
  margin-bottom: 15px;
  color: #0d1216;
  min-height: 50px;
}
.health-package-area .hp-item .hp-content h3 img {
  max-width: 35px;
  vertical-align: middle;
  position: absolute;
  left: 0;
  top: 0;
}
.health-package-area .hp-item .hp-content p {
  margin-bottom: 5px;
  font-size: 1.6rem;
  padding: 0 10px;
}
.health-package-area .hp-item .hp-content p i {
  font-size: 2rem;
  margin-right: 5px;
  vertical-align: baseline;
}
.health-package-area .hp-item .hp-price {
  margin: 20px 0 0 0;
  border-top: 1px solid #431a80;
  padding-top: 15px;
}
.health-package-area .hp-item .hp-price .discounted_price {
  margin-right: 15px;
  font-size: 2.2rem;
}
.health-package-area .hp-item .hp-price .actual_price {
  text-decoration: line-through;
}
.health-package-area .hp-item .hp-price .book__now {
  display: inline-block;
  color: #0d1216;
  background-color: #f2f3f5;
  font-size: 1.6rem;
  padding: 10px 15px;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: 0.3s all linear;
}
.health-package-area .hp-item .hp-price .book__now:hover {
  background-color: #431a80;
  color: #ffffff;
}
.health-package-area a.explore_packages {
  background-color: #ffffff;
  color: #0d1216;
  padding: 12px 45px;
  border: 2px solid transparent;
  border-radius: 4px;
}
.health-package-area a.explore_packages:hover {
  color: #431a80;
  border-color: #431a80;
}

.doctors-area h3 {
  font-size: 3.2rem;
  color: #0d1216;
}
.doctors-area p {
  font-size: 1.6rem;
  font-weight: 600;
}
.doctors-area p.nk__overview_info {
  font-size: 1.6rem;
  font-weight: normal;
  margin-bottom: 25px;
}
.doctors-area a.explore_more {
  display: inline-block;
  font-size: 1.8rem;
  padding: 10px 25px;
  border: 2px solid transparent;
  border-radius: 4px;
  background-color: #edf0f2;
  color: #0d1216;
}
.doctors-area a.explore_more:hover {
  color: #431a80;
  border-color: #431a80;
  background-color: #ffffff;
}

/** Logo **/
.nk__logo use {
  width: 100px;
  height: 200px;
}

.doctors-area {
  overflow: hidden;
}

.meet-our-doctors {
  background-color: #edf0f2;
}

@media (max-width: 1200px) {
  .header-top-item .header-top-left ul li a {
    font-size: 1.4rem;
  }
  .header-top-item .header-top-right ul li a {
    display: block;
    color: #c0b9b5;
    font-size: 2.8rem;
  }
  .main-nav nav .navbar-brand img {
    width: 225px;
  }
}
.nk__aboutus_page .nk__aboutus_intro span {
  font-weight: 700;
  font-size: 2rem;
}
.nk__aboutus_page .nk__waves.top.nk__light-bg {
  display: none;
}
.nk__aboutus_page .video-wrap {
  background: #0d1216;
}
.nk__aboutus_page .video-wrap .video-item {
  padding: 50px 25px;
}
.nk__aboutus_page .video-wrap .video-item .video-content {
  color: #ffffff;
  padding: 10px 0 10px 45px;
}
.nk__aboutus_page .video-wrap .video-item .video-content:before {
  width: 3px;
  background-color: #cd5e5e;
}
.nk__aboutus_page .video-wrap .video-item .video-content.nk__list_consultant h3 {
  font-size: 2.8rem;
  margin-bottom: 10px;
}
.nk__aboutus_page .video-wrap .react-tabs__tab-list .react-tabs__tab {
  color: #0d1216;
  background-color: #ffffff;
  font-size: 1.8rem;
  padding: 15px 25px;
  border-radius: 50px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.nk__aboutus_page .video-wrap .react-tabs__tab-list .react-tabs__tab.react-tabs__tab--selected {
  color: #ffffff;
  background-color: #431a80;
}

@media (max-width: 991px) {
  .nk__aboutus_page .video-area {
    height: auto;
  }
  .nk__aboutus_page .video-wrap .react-tabs__tab-list .react-tabs__tab {
    font-size: 1.6rem;
  }
  .nk__aboutus_page .video-item .video-content:before {
    top: 0;
    width: 3px;
    height: 100%;
  }
  .nk__aboutus_page .video-wrap .video-item {
    padding: 25px 10px;
  }
  .nk__aboutus_page .video-wrap .video-item .video-content {
    padding: 10px 0 10px 25px;
  }
  .nk__aboutus_page .video-wrap .video-item .video-content.nk__list_consultant {
    padding: 10px 0 10px 10px;
  }
  .nk__aboutus_page .video-item .video-content h3 {
    font-size: 2.4rem;
  }
  .nk__aboutus_page .video-wrap .video-item .video-content.nk__list_consultant h3 {
    font-size: 2rem;
  }
}
.footer-item .header-top-item .header-top-right ul li a {
  color: #0d1216;
}
.footer-item .header-top-item .header-top-right ul li a:hover {
  color: #431a80;
}

.footer-item .header-top-item {
  margin: 50px 0 0 0;
}

.footer-item .header-top-item .header-top-right {
  text-align: left;
}

.nk__about_intro_section {
  background-color: #431a80;
}
.nk__about_intro_section p {
  color: #ffffff;
}
.nk__about_intro_section .section-title h2 {
  color: #ffffff;
}
.nk__about_intro_section .section-title h2:before {
  background-color: #ffffff;
}

.location-area {
  background-color: #edf0f2;
}
.location-area .section-title h2 {
  color: #431a80;
}
.location-area .section-title h2:before {
  background-color: #ffffff;
}
.location-area .location-wrap .location-item {
  color: #0d1216;
  background-color: #ffffff;
  border-radius: 4px;
  border: 2px solid transparent;
  height: 100%;
  transition: 0.3s all linear;
}
.location-area .location-wrap .location-item:hover {
  border: 2px solid #431a80;
}
.location-area .location-wrap .location-item a:hover {
  color: #431a80;
}
.location-area .location-wrap .location-item h3 {
  color: #0d1216;
}
.location-area .location-wrap .location-item i {
  color: #431a80;
  background-color: #431a80;
  font-size: initial;
}
.location-area .location-wrap .location-item i img {
  max-width: 42px;
}

.map__wrap {
  background-color: #431a80;
}
.map__wrap .map-area {
  line-height: 0;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  overflow: hidden;
}

#nk__consultant_accordian .accordion .accordion__button {
  border-bottom: none;
  color: #fff;
  cursor: pointer;
  padding: 15px 35px 15px 18px;
  margin: 0;
  text-decoration: none;
  transition: 0.5s;
  position: relative;
  background: transparent;
  border: 1px solid #ffffff;
  border-radius: 8px 8px 0 0;
  color: #3a173c;
  background-color: #ffffff;
  font-size: 1.8rem;
  padding: 15px 25px;
  border-radius: 50px;
  margin-right: 15px;
  margin-bottom: 15px;
  border: none;
}
#nk__consultant_accordian .accordion .accordion__button::before {
  border-bottom: 3px solid #431a80;
  right: 35px;
}
#nk__consultant_accordian .accordion .accordion__button::after {
  border-right: 3px solid #431a80;
  right: 40px;
}
#nk__consultant_accordian .accordion .accordion__button:hover, #nk__consultant_accordian .accordion .accordion__button[aria-expanded=true] {
  background-color: #431a80;
  color: #ffffff;
  border: none;
}
#nk__consultant_accordian .accordion .accordion__button:hover::before, #nk__consultant_accordian .accordion .accordion__button[aria-expanded=true]::before {
  border-bottom: 3px solid #ffffff;
}
#nk__consultant_accordian .accordion .accordion__button:hover::after, #nk__consultant_accordian .accordion .accordion__button[aria-expanded=true]::after {
  border-right: 3px solid #ffffff;
}
#nk__consultant_accordian .accordion .accordion__panel {
  padding: 0;
}
#nk__consultant_accordian .accordion .accordion__item {
  box-shadow: none;
}

@media (max-width: 767px) {
  #nk__consultant_accordian .accordion .accordion__button::before {
    border-bottom: 3px solid #431a80;
    right: 15px;
  }
  #nk__consultant_accordian .accordion .accordion__button::after {
    border-right: 3px solid #431a80;
    right: 20px;
  }
}
.nk__quality_assurance_logos a {
  color: #ffffff;
}
.nk__quality_assurance_logos a span {
  display: block;
  font-size: 1.8rem;
  text-align: center;
}

.nk__qualityAssurance .section-title h2 {
  color: #ffffff;
}
.nk__qualityAssurance .section-title h2::before {
  background-color: #ffffff;
}
.nk__qualityAssurance a {
  max-width: 180px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  margin-right: 25px;
  color: #ffffff;
}
.nk__qualityAssurance a img {
  margin-bottom: 0px;
}
.nk__qualityAssurance a span {
  font-size: 1.8rem;
}
.nk__qualityAssurance h3 {
  font-size: 3.6rem;
  margin-bottom: 35px;
}
.nk__qualityAssurance .bg-purple p {
  color: #ffffff;
}
.nk__qualityAssurance .bg-lightpurple a {
  max-width: 360px;
  float: left;
}
.nk__qualityAssurance .bg-lightpurple a > span {
  float: left;
  max-width: 180px;
}
.nk__qualityAssurance .react-tabs__tab-list {
  margin-top: 35px;
  border: none;
}
.nk__qualityAssurance .react-tabs__tab-list .react-tabs__tab {
  color: #0d1216;
  background-color: #ffffff;
  font-size: 1.8rem;
  padding: 15px 25px;
  border-radius: 4px;
  margin-right: 15px;
  margin-bottom: 15px;
}
.nk__qualityAssurance .react-tabs__tab-list .react-tabs__tab:focus {
  box-shadow: none;
  border: none;
}
.nk__qualityAssurance .react-tabs__tab-list .react-tabs__tab:focus::after {
  display: none;
}
.nk__qualityAssurance .react-tabs__tab-list .react-tabs__tab--selected {
  color: #ffffff;
  background-color: #431a80;
  border: none;
}
.nk__qualityAssurance .nk__tab_content {
  padding: 0 0 10px 45px;
  position: relative;
  margin-top: 0;
}
.nk__qualityAssurance .nk__tab_content::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #431a80;
}

.nk__faclities_sub-page {
  background-color: #edf0f2;
}
.nk__faclities_sub-page .section-title h2 {
  color: #431a80;
}

@media (max-width: 767px) {
  .nk__qualityAssurance h3 {
    font-size: 2.8rem;
  }
  .nk__qualityAssurance a {
    display: block;
    margin-bottom: 25px;
  }
  .nk__qualityAssurance .bg-lightpurple a {
    display: inline;
    margin-bottom: 25px;
  }
  .nk__qualityAssurance .nk__tab_content {
    padding: 0 0 0 25px;
  }
}
.nk__empanelment_page h2::before {
  background-color: #ffffff;
}
.nk__empanelment_page .symptoms-content ul li {
  flex: inherit;
  max-width: inherit;
}
.nk__empanelment_page .symptoms-content ul li span {
  box-shadow: none;
  color: #0d1216;
  height: 100%;
}
.nk__empanelment_page .symptoms-content ul li span::before {
  background-color: #431a80;
}
.nk__empanelment_page .symptoms-content ul li span i {
  color: #0d1216;
  top: 10px;
  left: 0;
}
.nk__empanelment_page .symptoms-content ul li span:hover {
  color: #ffffff;
}
.nk__empanelment_page .symptoms-content ul li span:hover i {
  color: #ffffff;
}
.nk__empanelment_page .accordion .accordion__button:hover,
.nk__empanelment_page .accordion .accordion__button[aria-expanded=true] {
  background-color: #431a80;
  color: #ffffff;
  border: 2px solid #431a80;
}
.nk__empanelment_page .accordion .accordion__button {
  border-radius: 25px;
  font-size: 1.8rem;
  padding: 15px 25px;
  border-radius: 50px;
  margin-right: 15px;
  margin-bottom: 0;
  color: #0d1216;
  border: 2px solid #431a80;
}
.nk__empanelment_page .accordion .accordion__item {
  box-shadow: none;
}
.nk__empanelment_page .accordion .accordion__panel {
  padding: 15px 50px;
}

@media (max-width: 991px) {
  .nk__empanelment_page .symptoms-content ul li {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .doctors-area .mb-50 {
    margin-bottom: 70px;
  }
  .doctors-area h3 {
    font-size: 3rem;
  }
  .pd-r-60 {
    padding-right: 10px;
  }
  .pd-l-60 {
    padding-left: 10px;
  }
}
@media (max-width: 767px) {
  .nk__empanelment_page .symptoms-content ul li {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .nk__empanelment_page .accordion .accordion__panel {
    padding: 15px 15px;
  }
  .pd-r-60 {
    padding-right: 0;
    padding-bottom: 35px;
  }
  .pd-l-60 {
    padding-left: 0;
    padding-bottom: 35px;
  }
}
.nk__empanelment_grid {
  padding: 0;
}
.nk__empanelment_grid li {
  font-size: 1.6rem;
  border-radius: 50px;
  transition: all 0.2s ease;
  margin-bottom: 25px;
  list-style: none;
}
.nk__empanelment_grid li span {
  display: block;
  padding: 15px;
  color: #ffffff;
  background-color: #431a80;
  border-radius: 50px;
}
.nk__empanelment_grid li span i {
  font-size: 2.8rem;
  margin-right: 10px;
  color: #431a80;
  vertical-align: middle;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu li a {
  color: #ffffff;
  background-color: #431a80;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:hover,
.main-nav nav .navbar-nav .nav-item .dropdown-menu li a:focus {
  color: #ffffff;
  background-color: #0d1216;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu li a.active {
  color: #ffffff;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  border: 0;
  border-top: 4px solid #3a173c;
  padding: 0;
}

.main-nav nav .navbar-nav .nav-item .dropdown-menu {
  background: #cd5e5e;
}

.gallery-page {
  background-color: #edf0f2;
}
.gallery-page .section-title h2 {
  color: #ffffff;
}
.gallery-page .section-title h2::before {
  background-color: #ffffff;
}
.gallery-page .nk__gallery_wrap div {
  overflow: hidden;
  border-radius: 4px;
  outline: 2px solid #431a80;
}
.gallery-page .nk__gallery_wrap div img {
  width: 100%;
  height: 250px;
  border-radius: 4px;
  transition: 0.3s all linear;
}
.gallery-page .nk__gallery_wrap div:hover img {
  transform: scale(1.1);
}

.nk__radiology_page .nk__content_title {
  color: #0d1216;
  margin-top: 15px;
  background: #ffffff;
  padding: 0px 15px;
  border-radius: 4px;
  font-size: 18px;
  display: inline-flex;
  font-weight: 600;
}

.nk__radiology_page .nk__content_title.nk__block {
  display: block;
  background-color: #431a80;
  color: #ffffff;
}

.nk__radiology_page .nk__tab_content {
  padding: 0;
}

.nk__radiology_page img {
  border-radius: 15px;
}

.nk__radiology_page h4 {
  font-size: 28px;
  margin: 0 0 25px 0;
}

/* Zebra striping */
.nk__radiology_page .table {
  border: 1px solid #431a80;
  border-radius: 15px;
}

.nk__radiology_page tr:nth-of-type(odd) {
  background: #ffffff;
}

.nk__radiology_page tr:nth-of-type(odd) td {
  color: #0d1216;
}

.nk__radiology_page th {
  background: #431a80;
  color: #ffffff;
  font-size: 18px;
  font-weight: normal;
}

.nk__radiology_page td,
.nk__radiology_page th {
  padding: 10px;
  text-align: left;
}

.nk__docs .section-title h2:before {
  background-color: #ffffff;
}

.location-area.our-vision .location-wrap .location-item {
  text-align: left;
}
.location-area.our-vision .location-wrap .location-item img {
  max-width: 60px;
}
.location-area.our-vision .location-wrap .location-item h3 {
  text-align: left;
}
.location-area.our-vision .location-wrap .location-item p {
  text-align: left;
}

.nk__text-left {
  text-align: left !important;
}

.nk_facilities_page_container .location-item {
  cursor: pointer;
  transition: 0.3s all linear;
  margin-bottom: 0;
  padding: 40px 15px;
}
.nk_facilities_page_container .location-item p {
  text-align: center !important;
  margin-bottom: 25px;
}
.nk_facilities_page_container .location-item p img {
  max-width: 80px !important;
  transition: 0.3s all linear;
}
.nk_facilities_page_container .location-item h3 {
  text-align: center !important;
  font-size: 22px;
  margin-bottom: 0;
}
.nk_facilities_page_container .location-item:hover {
  background-color: #ffffff;
}
.nk_facilities_page_container .location-item:hover p img {
  transform: scale(1.2);
}

.nk__qualityAssurance .bg-purple p.nk__content_title {
  color: #3a173c;
  background: #ffffff;
  padding: 0px 15px;
  border-radius: 15px;
  display: -moz-inline-box;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 20px;
  display: inline-flex;
}

.slider-item {
  background-size: cover;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 460px;
  background-color: #431a80;
}

.nk_banner_wrap {
  position: relative;
  height: 460px;
}
.nk_banner_wrap .nk_banner_img {
  width: 565px !important;
  position: absolute;
  top: -45px;
}
.nk_banner_wrap .nk_banner_content {
  max-width: 500px;
  position: absolute;
  right: 0;
  top: 50% !important;
  transform: translateY(-50%);
}
.nk_banner_wrap .nk_banner_content p {
  color: #ffffff;
}
.nk_banner_wrap .nk_banner_content .banner__title {
  color: #ffffff;
  text-align: right;
  font-weight: 400;
  font-size: 4.6rem;
  line-height: 1.3;
  margin-bottom: 0;
  text-transform: uppercase;
}
.nk_banner_wrap .nk_banner_content .banner__subtitle {
  color: #ffffff;
  text-align: right;
  font-size: 2.2rem;
  margin-bottom: 0;
  line-height: 1.3;
  text-transform: uppercase;
}
.nk_banner_wrap .nk_banner_content .banner__subtitle2 {
  color: #ffffff;
  text-align: right;
  font-size: 2rem;
  margin-bottom: 0;
  line-height: 1.3;
}
.nk_banner_wrap .nk_banner_content .banner__subtitle3 {
  margin-top: 25px;
}
.nk_banner_wrap .nk_banner_content ul {
  list-style-type: none;
  padding: 0 0 0 15px;
  margin: 25px 0 0 0;
  text-align: left;
}
.nk_banner_wrap .nk_banner_content ul li {
  color: #ffffff;
  position: relative;
  padding-left: 25px;
  font-size: 1.8rem;
}
.nk_banner_wrap .nk_banner_content ul li i {
  position: absolute;
  left: 0;
  top: 5px;
}
.nk_banner_wrap .nk_banner_content .nk_appointment_button_wrap {
  text-align: right;
}
.nk_banner_wrap .nk_banner_content .nk_appointment_button_wrap .nk_appointment_button {
  background-color: #431a80;
  color: #ffffff;
  font-size: 1.8rem;
  margin-top: 25px;
  display: inline-block;
  border: 2px solid #ffffff;
  border-radius: 4px;
  padding: 6px 16px;
}
.nk_banner_wrap .nk_banner_content .nk_appointment_button_wrap .nk_appointment_button:hover, .nk_banner_wrap .nk_banner_content .nk_appointment_button_wrap .nk_appointment_button:focus {
  border-color: #ffffff;
  background-color: #ffffff;
  color: #431a80;
}

img.nk_banner_img_sub.img-fluid {
  position: absolute;
  width: 160px !important;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
}

@media (max-width: 1199px) {
  .nk_banner_wrap .nk_banner_img {
    width: 435px !important;
    top: 12px;
  }
  .home-slider.owl-theme .owl-nav .owl-prev,
  .home-slider.owl-theme .owl-nav .owl-next {
    bottom: 50% !important;
  }
}
@media (max-width: 991px) {
  .slider-item {
    height: 460px !important;
  }
  .nk_banner_wrap .nk_banner_img {
    width: 320px !important;
    top: 75px;
  }
  .nk_banner_wrap .nk_banner_content {
    max-width: 375px;
  }
  .nk_banner_wrap .nk_banner_content .banner__title {
    font-size: 3.6rem;
  }
  .nk_banner_wrap .nk_banner_content .banner__subtitle {
    font-size: 1.8rem;
  }
  img.nk_banner_img_sub.img-fluid {
    width: 135px !important;
  }
}
@media (max-width: 767px) {
  .slider-item:before {
    display: none;
  }
  .nk_banner_wrap .nk_banner_img {
    position: static;
    width: 78% !important;
    margin: 25px auto 0;
  }
  .slider-item {
    height: 100% !important;
    padding: 0;
    text-align: left;
  }
  .nk_banner_wrap .nk_banner_content {
    max-width: 100%;
    float: none;
    position: static;
    transform: none;
    margin: 25px 0 0 0;
  }
  .nk_banner_wrap .nk_banner_content .banner__title,
  .nk_banner_wrap .nk_banner_content .banner__subtitle,
  .nk_banner_wrap .nk_banner_content .banner__subtitle2 {
    text-align: left;
  }
  .nk_banner_wrap .nk_banner_content ul {
    padding: 0;
  }
  .nk_banner_wrap .nk_banner_content .nk_appointment_button {
    float: left;
  }
  .home-slider {
    max-height: 100%;
  }
  .nk_banner_wrap {
    position: relative;
    height: 820px;
  }
  img.nk_banner_img_sub.img-fluid {
    position: absolute;
    width: 135px;
    left: inherit;
    transform: none;
    top: 15px;
    right: 5px;
  }
}
.l-arrow {
  width: 15px !important;
  position: absolute;
  top: 3px;
  left: 0;
}

.liver_damage_stages_img {
  max-width: 425px;
}

@media (max-width: 991px) {
  #fibroscan_slide p {
    text-align: left !important;
  }
  #fibroscan_slide ul li {
    font-size: 1.4rem;
  }
  #fibroscan_slide ul li .l-arrow {
    width: 10px !important;
  }
}
@media (max-width: 767px) {
  .liver_damage_stages_img {
    max-width: 95%;
  }
  #fibroscan_slide ul li {
    font-size: 1.8rem;
    padding-right: 15px;
    padding-left: 20px;
    padding-bottom: 0px;
  }
  #fibroscan_slide ul li .l-arrow {
    width: 13px !important;
  }
  #fibroscan_slide .nk_appointment_button {
    margin-top: 25px !important;
  }
}
.home-collection-page {
  background-color: #431a80;
}
.home-collection-page .drop-item .drop-left h2 {
  color: #ffffff;
}
.home-collection-page .drop-item .drop-left textarea {
  resize: none;
  height: 50px !important;
  padding: 0.375rem 0.75rem;
  padding-top: 12px;
}
.home-collection-page .drop-item .drop-left .drop-btn {
  border-color: #f2f3f5;
  height: 50px;
  padding: 0 45px;
}
.home-collection-page .drop-item .drop-left .icofont-upload-alt {
  position: absolute;
  font-size: 25px;
  color: #431a80;
  top: 12px;
  left: 10px;
}
.home-collection-page .drop-item .drop-left .file-upload {
  padding: 12px 10px 12px 50px;
}
.home-collection-page .drop-item .drop-left .form-group select.form-control {
  -webkit-appearance: auto;
     -moz-appearance: auto;
          appearance: auto;
}
.home-collection-page .drop-item .invalid-feedback {
  color: #ffd5d9;
}/*# sourceMappingURL=style.css.map */