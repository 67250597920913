@import "../../sass/variables";

.nk__health-package-page {
  background-color: $light-grey;

  .section-title {
    text-transform: capitalize;
  }

  .nk__health-package__img {
    margin-bottom: 2.5rem;
  }

  .nk__health-package__info {
    &-title {
      font-size: 2.2rem;
      text-transform: capitalize;
      line-height: 1;
      margin-bottom: 1.5rem;
    }

    &-tests {
      span {
        color: $primary-color;
        font-weight: 600;
        font-size: 1.8rem;
      }
    }

    &-tests-list {
      list-style-type: none;
      padding-left: 0;
      margin-bottom: 3rem;
      li {
        text-transform: capitalize;
        i {
          color: $primary-color;
        }
      }
    }

    &-price {
      display: inline-block;
      color: $content-color;
      background-color: $white-color;
      font-size: 1.8rem;
      padding: 12px 35px;
      border: 2px solid $primary-color;
      border-radius: 4px;
    }

    &-book {
      color: $white-color;
      background-color: $primary-color;
      font-size: 1.8rem;
      padding: 12px 35px;
      border: 2px solid transparent;
      border-radius: 4px;
      float: right;

      &:hover {
        color: $white-color;
        background-color: $content-color;
        border-color: $primary-color;
      }
    }
  }
}
