@import "../../sass/variables";

.nk__health-package-slider {
  .owl-theme .owl-nav {
    margin-top: 0;
    position: absolute;
    top: -85px;
    right: 0;
  }

  .owl-theme .owl-nav [class*="owl-"] {
    display: inline-block;
    cursor: pointer;
    width: 55px;
    height: 55px;
    line-height: 55px;
    font-size: 28px;
    border-radius: 50%;
    color: $primary-color;
    background-color: $dark-grey;
    transition: $transition;
    padding: 0;

    &:hover {
      background-color: $primary-color;
      color: $white-color;
    }
  }

  .owl-theme .owl-dots {
    margin: 20px 0 0 0;
    display: none;
  }
}
