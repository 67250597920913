@import "../../../sass/variables";

.nk__health-packages {
  &--card {
    height: 100%;
    .card {
      height: 100%;
      transition: $transition;
      &-body {
        padding: 0 1.2rem 7rem;
      }
    }

    &-img-wrap {
      overflow: hidden;
      margin: 1.2rem;
    }

    &-img {
      transition: $transition;
    }

    &-title {
      font-size: 1.6rem;
      color: $content-color;
      margin: 0 0 0.5rem;
      font-weight: 700;
      line-height: 2.4rem;
      min-height: 4.8rem;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      line-clamp: 2;
      -webkit-box-orient: vertical;
      text-transform: uppercase;
    }
    &-des {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 3; /* number of lines to show */
      line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 1.6rem;
      line-height: 1.3;
    }
    &-tests {
      font-size: 1.4rem;
      color: $primary-color;
      vertical-align: middle;
      font-weight: 600;
    }

    &-explore {
      display: inline-block;
      font-size: 1.8rem;
      padding: 5px 20px;
      border: 2px solid transparent;
      border-radius: 4px;
      background-color: $dark-grey;
      color: $content-color;
      position: absolute;
      bottom: 1.2rem;
      right: 1.2rem;
      transition: $transition;
    }

    &-link {
      &:hover,
      &:focus {
        .card {
          border-color: $primary-color;
        }
        .nk__health-packages--card-explore {
          background-color: $primary-color;
          color: $white-color;
        }
        .nk__health-packages--card-img {
          transform: scale(1.2);
        }
      }
    }
  }
}
